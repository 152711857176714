import React, { useEffect, useState } from 'react'
import DashboardHeader from '../components/DashboardHeader'
import { AiOutlineArrowUp } from "react-icons/ai";
import ExpenseRow from '../components/ExpenseRow';
import { getData, User, updateLS, postData } from '../Data/Data';
import Spinner from '../components/Spinner';
import { render } from 'react-dom';
import ReportRow from '../components/ReportRow';

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [allExpenses, setAllExpenses] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [properties, setProperties] = useState([]);
  const [codes, setCodes] = useState([]);
  const [groups, setGroups] = useState([]);
  const [creditCards, setCreditCards] = useState([]);
  const [users, setUsers] = useState([]);
  const [limits, setLimits] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [editIndex, setEditIndex] = useState();
  const [total, setTotal] = useState();
  const [upload, setUpload] = useState();
  const [sortByProperty, setSortByProperty] = useState();
  const [sortByCode, setSortByCode] = useState();
  const [sortByCH, setSortByCH] = useState();
  const [separateAllocations, setSeparateAllocations] = useState(true);
  const [searchName, setSearchName] = useState();
  let currentID = 0;
  let currentPID = 0;
  let currentCID = 0;
  let totalSpend = 0;
  let totalCatSpend = 0;

  // setExpenses(GetData('api/users/allexpenses'));

  useEffect(async() => {
    await fetchProperties();
    await fetchCodes();
    await fetchGroups();
    fetchCreditCards();
    fetchUsers();
    fetchSpendingLimits();
    await fetchUploads();
  }, [])

  useEffect(() => {
    fetchExpenses(upload);
  }, [upload])
  
  const fetchExpenses = async (month) => {
    if(month){
      setExpenses([]);
      setIsLoading(true);
      const getAllExpenses = await getData(`api/users/AllReportExpenses/${month}`);
      setExpenses(getAllExpenses);
      setAllExpenses(getAllExpenses);
      
      let tempTotal = 0;
      getAllExpenses.map((expense) => {
        if(!separateAllocations && expense.alloParentID){
        } else if(separateAllocations && expense.allocations.length > 0){
        }else{
          tempTotal += expense.amount
        }
  
        expense.allocations.map(allocation => {
          allocation.displayAmount = (allocation.amount / 100).toFixed(2);
          properties.map(prop => {
            if(prop.id === allocation.propertyID){
              allocation.propertyName = prop.name 
            }
          })
          codes.map(code => {
            if(code.id === allocation.codeID){
              allocation.codeName = code.codeName 
            }
          })
          limits.map(limit => {
            if(limit.id === allocation.spendingLimitID){
              allocation.limitName = limit.name 
            }
          })
        })
      })
  
      setTotal((tempTotal / 100).toFixed(2))
  
      
      setIsLoading(false);
  
      return "";
    }
  }

  const fetchUploads = async () => {
    const getAllUploads = await getData('api/users/getuploads');
    setUploads(getAllUploads.reverse());
    setUpload(getAllUploads[0]);
  }

  const fetchProperties = async () => {
    const getAllProperties = await getData('api/users/getproperties');
    setProperties(getAllProperties.sort((a, b) => {
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    }));
  }

  const fetchCodes = async () => {
    const getAllCodes = await getData('api/users/getcodes');
    setCodes(getAllCodes);
  }

  const fetchGroups = async () => {
    const getAllGroups = await getData('api/users/getgroups');
    setGroups(getAllGroups);
  }
  
  const fetchCreditCards = async () => {
    if(User.type === 'SuperUser' || User.type == "Accountant"){
      const getAllCards = await getData('api/users/getcards');
      setCreditCards(getAllCards);
    } else {
      const getAllCards = await getData(`api/users/getmycards/${User.userID}`);
      setCreditCards(getAllCards);
    }
  }
  
  const fetchUsers = async () => {
    const getAllUsers = await getData('api/users/GetAllUsers');
    setUsers(getAllUsers);
  }
  
  const fetchSpendingLimits = async () => {
    const GetSpendingLimits = await getData('api/users/GetSpendingLimits');
    setLimits(GetSpendingLimits);
  }
    
  // const handleChange  = (e) => {
  //   const tempExpArr = [...expenses];

  //   if(e.target.name === "receipts"){
  //     tempExpArr[editIndex]["receipts"].push(e.target.value);
  //   }
  //   else{
  //     tempExpArr[editIndex][e.target.name] = e.target.value;
  //   }

  //   if(e.target.name === "propertyID"){
  //     if(e.target.value == 0){
  //       tempExpArr[editIndex][e.target.name] = null;
  //       tempExpArr[editIndex]["property"] = null;
  //     }
  //     else{
  //       properties.map(property => {
  //         if(property.id == e.target.value){
  //           tempExpArr[editIndex]["property"] = property;
  //         }
  //       })
  //     }
  //   }

  //   if(e.target.name === "codeID"){
  //     if(e.target.value == 0){
  //       tempExpArr[editIndex][e.target.name] = null;
  //       tempExpArr[editIndex]["code"] = null;
  //     }
  //     else{
  //       codes.map(code => {
  //         if(code.id == e.target.value){
  //           tempExpArr[editIndex]["code"] = code;
  //         }
  //       })    
  //     }
  //   }

  //   if(e.target.name === "spendingLimitID"){
  //     if(e.target.value == 0){
  //       tempExpArr[editIndex][e.target.name] = null;
  //       tempExpArr[editIndex]["spendingLimit"] = null;
  //     }
  //     else{
  //       limits.map(limit => {
  //         if(limit.id == e.target.value){
  //           tempExpArr[editIndex]["spendingLimit"] = limit;
  //         }
  //       })    
  //     }
  //   }

  //   setExpenses(tempExpArr);
  // }

  // const removeImage = (idx) => {
  //   const tempExpArr = [...expenses];

  //   const imageArr = tempExpArr[editIndex]["receipts"]
    
  //   const tempImageArr = [];
  //   imageArr.map((image, index) => {
  //     if(index != idx){
  //       tempImageArr.push(image)
  //     }
  //   })
  //   tempExpArr[editIndex]["receipts"] = tempImageArr;
  //   setExpenses(tempExpArr);
  // }

  const calcTotal = expenseArr => {
    let tot = 0;
    expenseArr.map(exp => {
      if(!separateAllocations && exp.alloParentID){
        return '';
      } else if(separateAllocations && exp.allocations.length > 0){
        return '';
      } else{
        tot += exp.amount
      }
    })
    setTotal((tot / 100).toFixed(2));
  }
  
  // const handleSaveExpense = async () => {
  //   const saveExpense = {

  //     id: expenses[editIndex].id,
  //     propertyID: expenses[editIndex].propertyID,
  //     codeID: expenses[editIndex].codeID,
  //     spendingLimitID: expenses[editIndex].spendingLimitID,
  //     notes: expenses[editIndex].notes,
  //   }
  //   const response = await postData("api/users/updateExpense", saveExpense);
  // };

  const handleChangeUpload = (e) => {
    setUpload(e.target.value);
  }
  
  // const handleSort = (e) => {
  //   setIsLoading(true);
  //   const tempUploads = [...allExpenses];
  //   if(e.target.value !== 7){
  //     setSortByCH(false);
  //   }
  //   if(e.target.value !== 3 || e.target.value !== 4){
  //     setSortByProperty(false);
  //   }
  //   if(e.target.value !== 9 || e.target.value !== 8){
  //     setSortByCode(false);
  //   }
  //   if(e.target.value == '1'){
  //     setSeparateAllocations(false);
  //     tempUploads.sort((a, b) => new Date(b.expense_Date) - new Date(a.expense_Date))
  //   }
  //   else if(e.target.value == '2'){
  //     setSeparateAllocations(false);
  //     tempUploads.sort((a, b) => new Date(a.expense_Date) - new Date(b.expense_Date))
  //   }
  //   else if(e.target.value == '3'){
  //     setSortByProperty(true);
  //     tempUploads.sort((a, b) => {
  //       if(!b.property){
  //         return 1
  //       }
  //       else if(!a.property){
  //         return -1
  //       }
  //       return b.propertyID - a.propertyID || a.codeID - b.codeID || a.spendingLimitID - b.spendingLimitID
  //     })
  //   }
  //   else if(e.target.value == '4'){
  //     setSortByProperty(true);
  //     tempUploads.sort((b, a) => {
  //       if(!b.property){
  //         return 1
  //       }
  //       else if(!a.property){
  //         return -1
  //       }
  //       return a.propertyID - b.propertyID || a.codeID - b.codeID || a.spendingLimitID - b.spendingLimitID
  //     })
  //   }
  //   else if(e.target.value == '8'){
  //     setSortByCode(true);
  //     tempUploads.sort((a, b) => {
  //       if(!b.code){
  //         return 1
  //       }
  //       else if(!a.code){
  //         return -1
  //       }
  //       return b.codeID - a.codeID || b.propertyID - a.propertyID || a.spendingLimitID - b.spendingLimitID
  //     })
  //   }
  //   else if(e.target.value == '9'){
  //     setSortByCode(true);
  //     tempUploads.sort((b, a) => {
  //       if(!b.code){
  //         return 1
  //       }
  //       else if(!a.code){
  //         return -1
  //       }
  //       return a.codeID - b.codeID || a.propertyID - b.propertyID || a.spendingLimitID - b.spendingLimitID
  //     })
  //   }
  //   else if(e.target.value == '5'){
  //     setSeparateAllocations(false);
  //     tempUploads.sort((b, a) => a.amount - b.amount)
  //   }
  //   else if(e.target.value == '6'){
  //     setSeparateAllocations(false);
  //     tempUploads.sort((a, b) => a.amount - b.amount)
  //   }
  //   else if(e.target.value == '7'){
  //     tempUploads.sort((a, b) => {
  //       setSortByCH(true);
  //       return a.userID - b.userID || a.propertyID - b.propertyID || a.codeID - b.codeID || a.spendingLimitID - b.spendingLimitID
  //     })
  //   }
    
  //   calcTotal(tempUploads);
  //   setExpenses(tempUploads);
  //   setIsLoading(false);
  // }

  // const handleSortCard = (e) => {
  //   if(e.target.value == ""){
  //     setExpenses(allExpenses);
  //     setSearchName(null);
  //     return "";
  //   }
  //   else{
  //     setSearchName(e.target.value);
  //   }
  //   const tempArr = []
  //   allExpenses.map(expense => {
  //     if(expense.ccNumber == e.target.value){
  //       tempArr.push(expense);
  //     }
  //   })
    
  //   calcTotal(tempArr);
  //   setExpenses(tempArr);
  // }
  
  // const handleSortHolder = (e) => {
  //   if(e.target.value == ""){
  //     setExpenses(allExpenses);
  //     return "";
  //   }
  //   const tempArr = []
  //   allExpenses.map(expense => {
  //     if(expense.userID == e.target.value){
  //       tempArr.push(expense);
  //     }
  //   })

  //   let name = users.map(user => {
  //     if(e.target.value == user.userID){
  //       return user.name;
  //     }
  //   })

  //   tempArr.sort((a, b) => {
  //     setSortByCH(true);
  //     return a.propertyID - b.propertyID || a.codeID - b.codeID
  //   })

  //   setSearchName(name);
  //   calcTotal(tempArr);
  //   setExpenses(tempArr);
  // }
  
  const handleSortProperty = (e) => {
    if(e.target.value == ""){
      setExpenses(allExpenses);
      return "";
    }
    const tempArr = []
    allExpenses.map(expense => {
      if(expense.propertyID == e.target.value){
        tempArr.push(expense);
      }
    })

    let name = properties.map(property => {
      if(e.target.value == property.id){
        return property.name;
      }
    })
    
    tempArr.sort((a, b) => {
      setSortByProperty(true);
      return a.codeID - b.codeID
    })

    setSearchName(name);
    calcTotal(tempArr);
    setExpenses(tempArr);
  }
  
  // const handleSortCode = (e) => {
  //   if(e.target.value == ""){
  //     setExpenses(allExpenses);
  //     return "";
  //   }
  //   const tempArr = []
  //   allExpenses.map(expense => {
  //     if(expense.codeID == e.target.value){
  //       tempArr.push(expense);
  //     }
  //   })

  //   let name = codes.map(code => {
  //     if(e.target.value == code.id){
  //       return code.name;
  //     }
  //   })
    
  //   tempArr.sort((a, b) => {
  //     setSortByProperty(true);
  //     return a.spendingLimitID - b.spendingLimitID
  //   })

  //   setSearchName(name);
  //   calcTotal(tempArr);
  //   setExpenses(tempArr);
  // }

  // const deleteExpense = async (id) => {
  //   const tempExpArr = [...expenses];
  //   const newArr = [];
  //   tempExpArr.map((exp, idx) => {
  //     if(idx !== editIndex){
  //       newArr.push(exp)
  //     }
  //   })

  //   setExpenses(newArr);

  //   const response = await getData(`api/users/deletedxpense/${id}`);
    
  //   const getAllExpenses = await getData(`api/users/allexpenses/${upload}`);
  //   setAllExpenses(getAllExpenses);
  // }

  // const handlePrintReport = () => {
  //   const header = document.getElementById("top-header")
  //   const sortBar = document.getElementById("search-sort-bar")
  //   const h2 = document.getElementById("h2-expense-header")
  //   const report = document.getElementById("report")
  //   setPrinting(true)
  //   header.style.display = "none"
  //   sortBar.style.display = "none"
  //   h2.style.display = "none"
  //   report.style.overflow = "visible"
  //   report.style.height = "auto"
  //   setTimeout(() => {
  //     window.print()
  //     header.style.display = "flex"
  //     sortBar.style.display = "flex"
  //     h2.style.display = "block"
  //     report.style.overflowY = "scroll"
  //     report.style.height = "calc(100vh - 300px)"
  //     setPrinting(false);
  //   }, 50)
  // }

  // const handleChangeAllocation = (e, idx) => {
  //   const tempExpArr = [...expenses];
  //   if(e.target.name == "add"){
  //     tempExpArr[editIndex].allocations.push({});
  //     setSeparateAllocations(false);
  //   }
  //   else if(e.target.name == "delete"){
  //     let alloArr = []
  //     tempExpArr[editIndex].allocations.map((allo, index) => {
  //       if(idx !== index){
  //         alloArr.push(allo);
  //       }
  //       else{
  //         getData(`api/users/deletedxpense/${allo.id}`);
  //       }
  //     })
  //     tempExpArr[editIndex].allocations = alloArr;
  //   }
  //   else{
  
  //     tempExpArr[editIndex].allocations[idx][e.target.name] = e.target.value
  //     if(e.target.name == "displayAmount"){
  //       tempExpArr[editIndex].allocations[idx].amount = (e.target.value * 100).toFixed(0);
  //     }
  //   }
  //   setExpenses(tempExpArr);
  // }

  // const refreshPage = () => {
  //   window.location.reload();
  // }

  return  (
    <div className="body-page">
      <DashboardHeader />
      <div className="dashboard-body">
        <div className="body-content">
          <h2 id='h2-expense-header'>Reports</h2>
          <div id='search-sort-bar' className="search-sort flex-row-wrap">
            <select className='sort-select' onChange={handleChangeUpload}>
              {
                uploads.map((upload, idx) => {
                  if(idx === 0){
                    return <option defaultValue key={upload} value={upload}>{upload}</option>
                  }
                  else{
                    return <option key={upload} value={upload}>{upload}</option>
                  }
                })
              }
            </select>
            {/* <select className='sort-select' onChange={handleSort}>
              <option value="1">Date Asc</option>
              <option value="2">Date Desc</option>
              <option value="3">Property Asc</option>
              <option value="4">Property Desc</option>
              <option value="8">GL Code Asc</option>
              <option value="9">GL Code Desc</option>
              <option value="5">Amount &#8595;</option>
              <option value="6">Amount &#8593;</option>
              <option value="7">Card Holder</option>
            </select> */}
            <select className='sort-select' onChange={handleSortProperty}>
              <option value="">Choose Property...</option>
              {
                properties.map(property => {
                  return (
                    <option key={`card_${property.id}`} value={property.id}>{property.name}</option>
                )})
              }
            </select>
            {/* <select className='sort-select' onChange={handleSortCode}>
              <option value="">Choose GL Code...</option>
              {
                codes.map(code => {
                  return (
                    <option key={`card_${code.id}`} value={code.id}>{code.codeName}</option>
                )})
              }
            </select> */}
            {/* <select className='sort-select' onChange={handleSortCard}>
              <option value="">Choose Card...</option>
              {
                creditCards.map(card => (
                  <option key={`card_${card.id}`} value={card.number}>{card.number}</option>
                ))
              }
            </select> */}
            {/* {
              (User.type == "SuperUser" || User.type == "Accountant") &&
              <select className='sort-select' onClick={handleSortHolder}>
                <option value="">Choose Card Holder...</option>
                {
                  users.map(user => (
                    <option key={`user_${user.userID}`} value={user.userID}>{user.name}</option>
                  ))
                }
              </select>
            } */}
            {/* <button className='btn btn-primary print-btn' onClick={handlePrintReport}>Print</button>
            <button className='btn btn-primary print-btn' onClick={refreshPage}>Refresh</button> */}
          </div>
          <div className="table content-table expense-content">
            <div className={"tr th expense-row reports-row"}>
              <div className="td hd date">Date</div>
              <div className="td hd expense-desc description">Description</div>
              <div className="td hd ccnumber">CC#</div>
              <div className="td hd hidden-lable amount">Amount</div>
              <div className="td hd prop-code-select property">Property</div>
              <div className="td hd prop-code-select code">GL Code</div>
              <div className="td hd limit">Type</div>
              <div className="td hd print-hide approveddenied">Approved</div>
            </div>
            <div id='report' className="table-body expense-table">
              {
                searchName &&
                <div className="new-user-row">Name: {searchName}</div>
              }
              {
                !isLoading 
                ? 
                expenses.map((item, idx) => {
                  let tempUser;
                  let tempCat;
                  let setTotalSpend;
                  let setCatSpend;

                  if(sortByCH){
                    if(currentID !== item.userID){
                      currentID = item.userID;
                      setTotalSpend = totalSpend;
                      totalSpend = 0;
                      tempUser = users.map(user => {
                        if(user.userID == item.userID){
                          return user.name
                        }
                      })
                    }
                    totalSpend += item.amount;
                  }
                  if(sortByProperty){
                    if(currentPID !== item.propertyID){
                      currentPID = item.propertyID;
                      setTotalSpend = totalSpend;
                      totalSpend = 0;
                      tempUser = properties.map(property => {
                        if(property.id == item.propertyID){
                          return property.name
                        }
                      })
                    }
                    totalSpend += item.amount;
                  }
                  if(sortByCode){
                    if(currentPID !== item.codeID){
                      currentPID = item.codeID;
                      setTotalSpend = totalSpend;
                      totalSpend = 0;
                      tempUser = codes.map(code => {
                        if(code.id == item.codeID){
                          return code.codeName
                        }
                      })
                    }
                    totalSpend += item.amount;
                  }
                  if(sortByProperty || sortByCH || sortByCode){
                    if(currentCID !== item.codeID){
                      currentCID = item.codeID;
                      setCatSpend = totalCatSpend;
                      totalCatSpend = 0;
                      tempCat = "Hey";
                      // = properties.map(property => {
                      //   if(property.id == item.propertyID){
                      //     return property.name
                      //   }
                      // })
                    }
                    totalCatSpend += item.amount;
                  }
                  return (
                    <>
                      {setCatSpend !== 0 && tempCat ? 
                      <div className="total-cat-row">
                        ${(setCatSpend / 100).toFixed(2).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </div> : ""}
                      {setTotalSpend !== 0 && tempUser ? 
                      <div className="total-spand-row">
                        <div></div>
                        <div></div>
                        <div className='pagebreak'>Total Spend: ${(setTotalSpend / 100).toFixed(2).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      </div> : ""}
                      {tempUser ? <div className="new-user-row">{tempUser}</div> : ""}
                      <ReportRow 
                          key={`expense_${item.id}`} 
                          rowContent={item} 
                        />
                      </>
                    )
                  })
                :
                <div className="spinner-box">
                  <Spinner />
                </div>
              }
              <div className="total-cat-row">
                ${(totalCatSpend / 100).toFixed(2).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </div>
            {/* <div className={!printing ? "tr th expense-row" : "tr th expense-row expense-row-print"}>
              {
                !printing &&
                <div className="td hd"></div>
              }
              <div className="td hd">Total Spend: ${total && total.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
              <div className="td hd"></div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard