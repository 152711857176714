import React, { useEffect, useState } from 'react';
import CardHolderRow from '../components/CardHolderRow'
import DashboardHeader from '../components/DashboardHeader'
import Navbar from '../components/Navbar'
import { GiHamburgerMenu } from "react-icons/gi";
import CardHolderModal from '../components/CardHolderModal';
import PropertiesModal from '../components/PropertiesModal';
import PropertiesRow from '../components/PropertiesRow';
import GLModal from '../components/GLModal';
import GLCodesRow from '../components/GLCodesRow';
import GroupRow from '../components/GroupRow';
import GroupModal from '../components/GroupModal';
import { getData, postData } from '../Data/Data';

function Groups() {
  const [showNewModal, setShowNewModal] = useState(false);
  const [newGroup, setNewGroup] = useState({
    groupName: "",
    active: true
  });
  const [groups, setGroups] = useState([]);
  const [modGroups, setModGroups] = useState(0);
  const [editIndex, setEditIndex] = useState();

  useEffect(async () => {
    const getGroups = await getData("api/users/getgroups")
    setGroups(getGroups);
  }, [modGroups])
  
  const dispayNav = () => {
    const navbar = document.getElementById('navbar')
    navbar.style.display = 'flex';
  }

  const deleteGroup = async (id) => {
    const response = await postData("api/users/deletegroup", {groupID: id});
    setModGroups(modGroups + 1);

  }

  const saveNewGroup = async (newGroup) => {
    const response = await postData("api/users/SaveNewGroup", newGroup);
    setModGroups(modGroups + 1);
    setShowNewModal(false);
  }

  const handleChangeGroup = (e) => {
    setNewGroup((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value == "true" ? false : e.target.value == "false" ? true : e.target.value,
    }))
  }
  
  const handleUpdateGroups = (e) => {
    const tempGroups = [...groups];
    if((tempGroups[editIndex][e.target.id] === null || tempGroups[editIndex][e.target.id] === "") && (e.target.id === "spendingLimits" || e.target.id === "approvalNeeded")){
      tempGroups[editIndex][e.target.id] = true;
    }
    tempGroups[editIndex][e.target.id] = e.target.value === "true" ? false : e.target.value === "false" ? true : e.target.value;
    setGroups(tempGroups);

  }

  return (
    <div className="body-page dashboard-page">
      <div className="hamburger">
       <GiHamburgerMenu className='hamberger-icon' onClick={() => dispayNav()} />   
      </div>
      <DashboardHeader />
      <div className="dashboard-body dashboard-body-navbar row">
        <Navbar />
        <div className="dashboard-body-content">
          <h1 className='dashboard-h1'>Groups</h1>
          <button className="btn btn-primary btn-quarter-width margin-bottom-xl" onClick={() => setShowNewModal(true)}>Add Property</button>
          <div className="table approvers-table">
            <div className="tr">
              <div className="th td">Groups</div>
            </div>
            {
              groups.map((group, idx) => {
                return <GroupRow 
                  key={group.id} 
                  group={group} 
                  setEditIndex={() => setEditIndex(idx)} 
                  handleChangeGroup={handleUpdateGroups}
                  deleteGroup={deleteGroup} />
              })
            }
          </div>
        </div>
      </div>
      {
        showNewModal
        ?
        <GroupModal 
          group={newGroup} 
          handleSaveGroup={saveNewGroup}
          handleChangeGroup={handleChangeGroup}
          closeModal={() => setShowNewModal(false)} 
        />
        :
        <></>
      }
    </div>
  )
}

export default Groups