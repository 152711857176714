import React, { useState } from 'react';
import { baseUrl, getData, postData } from '../Data/Data';
import { GrClose } from "react-icons/gr";


function ApproveExpenseRow({
  expense,
  index,
  handleUpdateExpense,
  setEditIndex,
  addExpense,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const { 
    id,
    receipts,
    cardHolderName, 
    description, 
    displayAmount, 
    amount,
    approved, 
    ccNumber, 
    expense_Date, 
    notes, 
    property, 
    propertyID,
    code,
    codeID,
    spendingLimit,
    spendingLimitID,
    allocations
  } = expense;
  
  let expenseDate;

  const formDate = new Date(expense_Date);

  const setExpenseDate = () => {
    var dd = String(formDate.getDate()).padStart(2, '0');
    var mm = String(formDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = formDate.getFullYear();
    
    expenseDate = mm + '/' + dd + '/' + yyyy;
    
  }

  const handleApprove = async (expenseID, approve) => {
    const response = await getData(`api/users/ApproveExpenses/${expenseID}/${approve}`)
    if(response === "Success"){
      handleUpdateExpense({target: { name: "approved", value: approve }}, index)
    }
  }

  const handleEdit = async () => {
    const response = await postData(`api/users/UpdateApproveExpense`, {ID: id, Notes: notes})
  }

  const handleRowClick = () => {
    setEditIndex()
    setShowDetails(true)
  } 

  const approvalReady = () => {
    if(expense.propertyID && 
      expense.codeID && 
      (!expense.requireLimits || expense.spendingLimitID) && 
      (!expense.requireReceipts || expense.receipts.length !== 0 || expense.alloParent?.receipts.length > 0) && 
      ((expense.spendingLimit?.limit <= expense.amount && expense.notes) || ( !expense.requireLimits || expense.spendingLimit?.limit > expense.amount) )){
        if(expense.approved == null){
          addExpense(expense.id);
        }
        return true;
    }
    else{
      return false
    }
  }

  setExpenseDate();

  return (
    <div>

      <div className='tr approve-row' onClick={() => {
        handleRowClick()
      }}>
        <div className="td">{expenseDate}</div>
        <div className="td">{cardHolderName}</div>
        <div className="td">{description}</div>
        <div className="td">{property && property.name}</div>
        <div className="td">{code && code.codeName}</div>
        <div className={spendingLimit && spendingLimit.limit >= amount ? "td" : "td denied"}>${displayAmount.toFixed(2)}</div>
        <div className="td">{spendingLimit && spendingLimit.name}</div>
        <div className='approve-btn-box center' onClick={(e) => e.stopPropagation()}>
          {
            approved === false &&
            <div className="denied reject-font">Rejected</div>
          }
          {
            approvalReady() ? (
              !approved &&
              <button 
                className="btn btn-approve"
                onClick={() => {
                  setShowDetails(false)
                  handleApprove(id, true)}
                }
              >
                Approve
              </button>
            )
            :
            <div className="td denied">Awaiting Items</div>
          }
  
        </div>
        {
          allocations &&
          allocations.map((allo, idx) => {
            return (
            <>
              <div className={idx === 0 ? 'allocation-approve-start allocation-text top-allocation' : 'allocation-approve-start allocation-text'}>{allo.propertyName}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation' : 'allocation-text'}>{allo.codeName}</div>
              <div className={idx === 0 ? 'allocation-approve-end allocation-text top-allocation' : 'allocation-text'}>${allo.displayAmount}</div>
            </>

          )})
        }
          <div className="table-font">{receipts.length > 0 ? receipts.map((receipt, idx) => (
            <div className="image-tag">
              <a href={`${baseUrl.substring(0, baseUrl.length-1)}${receipt.filePath}`} target="_blank">Image {idx+1}</a>
            </div>
          )) :
          expense.alloParent?.receipts.map((receipt, idx) => (
            <div className="image-tag">
              <a href={`${baseUrl.substring(0, baseUrl.length-1)}${receipt.filePath}`} target="_blank">Image {idx+1}</a>
            </div>
          ))
          }</div>
        <div className="td approve-message"  onClick={() => setShowDetails(true)}><b>Message: </b>{notes}</div>
      </div>
        {
          showDetails
          ?
          <div className='popup-box'>
            <div className="overlay card-holder-details" onClick={() => setShowDetails(!showDetails)}></div>
            <div className="details-card expense-popup">
              <GrClose className='close-btn' onClick={() => setShowDetails(!showDetails)} />
              <h3>{description}</h3>
              <div className="data-point">
                <label className="profile-label">Date:</label>
                <div className="profile-data">{expenseDate}</div>
              </div>
              <div className="data-point">
                <label className="profile-label">Amount:</label>
                <div className={spendingLimit?.limit >= amount ? "profile-data" : "profile-data denied"}>${displayAmount}</div>
              </div>
              <div className="data-point">
                <label className="profile-label">Card #:</label>
                <div className="profile-data">{ccNumber}</div>
              </div>
              <div className="data-point">
                <label className="profile-label">Property:</label>
                <div className="profile-data">{property && property.name}</div>
              </div>
              <div className="data-point">
                <label className="profile-label">GL Code:</label>
                <div className="profile-data">{code && code.codeName}</div>
              </div>
              <div className="data-point">
                <label className="profile-label">Type:</label>
                <div className="profile-data">{spendingLimit && spendingLimit.name}</div>
              </div>
              <div className="data-point">
                <label className="profile-label">Receipts:</label>
                  <div className="profile-data image-tags">{receipts.map((receipt, idx) => (
                    <div className="image-tag">
                      <a href={`${baseUrl.substring(0, baseUrl.length-1)}${receipt.filePath}`} target="_blank">Image {idx+1}</a>
                    </div>
                ))}</div>
              </div>
              <div className="data-point">
                <label className="profile-label">Message:</label>
                <textarea 
                  type="text" 
                  className='edit-textarea'
                  name="notes"
                  value={notes} 
                  onChange={handleUpdateExpense}
                ></textarea>
              </div>
              <div className="buttons-row">
                <button 
                  className="btn btn-primary btn-full-width btn-danger" 
                  onClick={() => {
                    handleApprove(id, false)
                    handleEdit();
                    setShowDetails(!showDetails)}
                  }
                >
                  Reject
                </button>
                <button 
                  className="btn btn-primary btn-full-width btn-approve" 
                  onClick={() => {
                    handleApprove(id, true)
                    handleEdit();
                    setShowDetails(!showDetails)}
                  }
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
          :
          <></>
        }
    </div>
  )
}

export default ApproveExpenseRow