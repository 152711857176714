import React, { useEffect, useState } from 'react';
import DashboardHeader from '../components/DashboardHeader'
import Navbar from '../components/Navbar'
import { GiHamburgerMenu } from "react-icons/gi";
import GLModal from '../components/GLModal';
import GLCodesRow from '../components/GLCodesRow';
import { getData, postData } from '../Data/Data';

function GLCodes() {
  const [showNewModal, setShowNewModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [allCodes, setAllCodes] = useState([]);
  const [codes, setCodes] = useState([]);
  const [modCodes, setModCodes] = useState(0);
  const [newCode, setNewCode] = useState({ active: true });
  const [editIndex, setEditIndex] = useState();

  useEffect(async () => {
    const getCodes = await getData("api/users/getcodes")
    setAllCodes(getCodes);
    setCodes(getCodes);
    const getGroups = await getData("api/users/getgroups")
    setGroups(getGroups);
  }, [modCodes])

  const dispayNav = () => {
    const navbar = document.getElementById('navbar')
    navbar.style.display = 'flex';
  }

  const handleChangeCode = (e) => {
    setNewCode((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value === "true" ? false : e.target.value === "false" ? true : e.target.value,
    }))
  }

  const handleSaveCode = async (newCode) => {
    const response = await postData("api/users/SaveNewCode", newCode);
    setModCodes(modCodes + 1);
    setShowNewModal(false);
  }

  const handleChangeCodes = (e) => {
    const tempCodes = [...codes];
    tempCodes[editIndex][e.target.id] = e.target.value === "true" ? false : e.target.value === "false" ? true : e.target.value;
    
    if(e.target.id === "groupID"){
      groups.map(group => {
        if(group.groupID == e.target.value){
          tempCodes[editIndex]["group"] = group;
        }
      })  
    }
    setCodes(tempCodes);
  }

  const searchCodes = (e) => {
    const tempArr = [];
    if(e.target.value == ""){
      setCodes(allCodes);
    }
    allCodes.map(code => {
      if(code.codeName.toLowerCase().includes(e.target.value.toLowerCase())){
        tempArr.push(code);
      }
    })
    setCodes(tempArr);
  }

  return (
    <div className="body-page dashboard-page">
      <div className="hamburger">
       <GiHamburgerMenu 
        className='hamberger-icon' 
        onClick={() => dispayNav()} 
      />   
      </div>
      <DashboardHeader />
      <div className="dashboard-body dashboard-body-navbar row">
        <Navbar />
        <div className="dashboard-body-content">
          <h1 className='dashboard-h1'>GL Codes</h1>
          <button 
            className="btn btn-primary btn-quarter-width margin-bottom-xl" 
            onClick={() => setShowNewModal(true)}
          >Add GL Code</button>
          <input type="text" placeholder='Search' onChange={searchCodes} />
          <div className="table code-table">
            <div className="tr">
              <div className="th td">GL Codes</div>
              <div className="th td">Group</div>
              <div className="th td">Active</div>
            </div>
            {
              codes.map((code, idx) => {
                return (
                <GLCodesRow 
                  key={code.id} 
                  setEditIndex={() => setEditIndex(idx)} 
                  code={code} 
                  groups={groups}
                  handleChangeCode={handleChangeCodes}
                />
              )})
            }
          </div>
        </div>
      </div>
      {
        showNewModal
        ?
        <GLModal 
          code={newCode} 
          closeModal={() => setShowNewModal(false)}
          groups={groups}
          handleChangeCode={handleChangeCode}
          handleSaveCode={handleSaveCode}
        />
        :
        <></>
      }
    </div>
  )
}

export default GLCodes