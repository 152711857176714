import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaFileAlt, FaMoneyBillWave, FaLayerGroup } from 'react-icons/fa';
import { AiFillCreditCard, AiOutlineBarcode, AiOutlineCheckCircle } from "react-icons/ai";
import { BiBuildingHouse } from "react-icons/bi";
// import { IoBarcodeSharp } from "react-icons/io";
import { MdImportExport } from "react-icons/md";
import { MdClose } from "react-icons/md";


function Navbar() {
  const closeNav = () => {
    const navbar = document.getElementById('navbar');
    navbar.style.display = 'none';
  }

  return (
    <div id='navbar' className='navbar'>
      <MdClose id='nav-close' className='nav-close' onClick={() => closeNav()} /> 
      <div className="nav-body">
        <div className="nav-body-top">
          <NavLink to='/Manage-Card-Holders' className={({ isActive }) => (isActive ? 'navbar-link active-nav-link' : 'navbar-link')} >
            <AiFillCreditCard className='nav-link-logo' />
            Card Holders
          </NavLink>
          <NavLink to='/Card-Holder-Approvers'  className={({ isActive }) => (isActive ? 'navbar-link active-nav-link' : 'navbar-link')} >
            <AiOutlineCheckCircle className='nav-link-logo' />
            Approvers
          </NavLink>
          {/* <NavLink to='/Reports' className={({ isActive }) => (isActive ? 'navbar-link active-nav-link' : 'navbar-link')} >
            <FaFileAlt className='nav-link-logo' />
            Reports
          </NavLink> */}
          <NavLink to='/Properties' className={({ isActive }) => (isActive ? 'navbar-link active-nav-link' : 'navbar-link')} >
            <BiBuildingHouse className='nav-link-logo' />
            Properties
          </NavLink>
          <NavLink to='/GL-Codes' className={({ isActive }) => (isActive ? 'navbar-link active-nav-link' : 'navbar-link')} >
            <AiOutlineBarcode className='nav-link-logo' />
            GL Codes
          </NavLink>
          <NavLink to='/Groups' className={({ isActive }) => (isActive ? 'navbar-link active-nav-link' : 'navbar-link')} >
            <FaLayerGroup className='nav-link-logo' />
            Groups
          </NavLink>
          <NavLink to='/Spending-Limits' className={({ isActive }) => (isActive ? 'navbar-link active-nav-link' : 'navbar-link')} >
            <FaMoneyBillWave className='nav-link-logo' />
            Spending Limits
          </NavLink>
          <NavLink to='/Import-Export' className={({ isActive }) => (isActive ? 'navbar-link active-nav-link' : 'navbar-link')} >
            <MdImportExport className='nav-link-logo' />
            Imports
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
