import React, { useState, useEffect } from 'react';
import { GrClose } from "react-icons/gr";
import { BiTrash } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import PropTypes from 'prop-types';


function GroupModal({group, closeModal, handleSaveGroup, handleChangeGroup}) {
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(true);
  const [addCard, setAddCard] = useState(true);

  const addCardFunc = () => {
    const addInput = document.getElementById("add-card-input");
  }
  

  return (
    <div className='popup-box'>
      <div className="overlay card-holder-details" onClick={() => closeModal()}></div>
      <div className="details-card">
        <GrClose className='close-btn' onClick={() => closeModal()} />
        <h3>Groups</h3>
        <div className="data-point">
          <label className='profile-label'>Name: </label>
          <input 
            type="text" 
            className='gl-edit' 
            value={group.groupName} 
            id="groupName"
            onChange={handleChangeGroup}
          />
        </div>
        <div className="data-point">
          <label className='profile-label'>Active: </label>
          {
            editDetails
            ?
            <input 
              type="Checkbox" 
              className='edit-details' 
              id="active"
              name='active'
              onChange={handleChangeGroup}
              value={group.active} 
              checked={group.active}
            />
            :
            <div className="profile-data">{group.active ? "Yes" : "No"}</div>
          }
        </div>
        <button 
          className="btn btn-primary btn-full-width margin-top-small"
          onClick={() => handleSaveGroup(group)}
        >
            Save
          </button>
      </div>
    </div>
  )
}

GroupModal.defaultProps = {

};

GroupModal.propTypes = {
  
};

export default GroupModal