import React, { useState } from 'react';
import { BiTrash } from "react-icons/bi";
import { patchData, postData } from '../Data/Data';
import GLModal from './GLModal';
import GroupModal from './GroupModal';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";

function GroupRow({
  group, 
  deleteGroup,
  setEditIndex,
  handleChangeGroup,
}) {
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleSaveGroup = async () => {
    await patchData('api/users/PatchGroup', group);
    setShowModal(false)
  }

  return (
    <div>
      <div 
        className='gl-code-row' 
        onClick={() => {
          setEditIndex()
          setShowModal(true)
      }}>
        <p className='gl-text'>{group.groupName}</p>
        <div className="td center">
          {
            group.active === true
            ?
            <AiOutlineCheckCircle className='approve-expence-btn approved' /> 
            :
            group.active === false
            ?
            <TiDeleteOutline className='approve-expence-btn denied' />
            :
            ""
          }
        </div>
        {/* <BiTrash 
          onClick={async () => {
            setDeleting(true);
            setShowModal(false);
            await deleteGroup(group.groupID);
            setDeleting(false);
          }} 
          className='trash-it'
        /> */}
      </div>
      {
        showModal && !deleting
        ?
        <GroupModal 
          group={group} 
          handleSaveGroup={handleSaveGroup}
          handleChangeGroup={handleChangeGroup}
          closeModal={() => setShowModal(false)} 
        />
        :
        <></>
      }
    </div> 
  )
}

export default GroupRow