import React, { useEffect, useState } from 'react';
import DashboardHeader from '../components/DashboardHeader'
import ApproveExpenseRow from '../components/ApproveExpenseRow';
import { User, getData, postData } from '../Data/Data';
import Spinner from '../components/Spinner';

function ApproveExpense() {
  const [isLoading, setIsLoading] = useState(true);
  const [expenses, setExpenses] = useState([]);
  const [editIndex, setEditIndex] = useState();
  const [upload, setUpload] = useState();
  const [properties, setProperties] = useState([]);
  const [codes, setCodes] = useState([]);
  const [allExpenses, setAllExpenses] = useState([]);
  const [users, setUsers] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [showApproveAllBtn, setShowApproveAllBtn] = useState(false);
  let massApproveArr = [];


  
  useEffect(async () => {
    await fetchUsers();
    await fetchProperties();
    await fetchCodes();
    await fetchUploads();
  }, [])

  useEffect(async () => {
    await fetchExpenses(upload);
  }, [upload])
  
  const handleChangeUpload = (e) => {
    setUpload(e.target.value);
  }

  const fetchExpenses = async (month) => {
    if(month){
      setExpenses([]);
      setIsLoading(true);
      // const getAllExpenses = await getData(`api/users/allexpenses/${month}`);
      const getAllExpenses = await getData(`api/users/allapprovals/${month}`);
      getAllExpenses.sort((a, b) => {
        return (a.approved === b.approved) ? 0 : a.approved ? 1 : -1 
      })
      await setExpenses(getAllExpenses);
      await setAllExpenses(getAllExpenses);
      
      let tempTotal = 0;
      getAllExpenses.map((expense) => {
        tempTotal += expense.displayAmount
  
        expense.allocations.map(allocation => {
          allocation.displayAmount = (allocation.amount / 100).toFixed(2);
          properties.map(prop => {
            if(prop.id === allocation.propertyID){
              allocation.propertyName = prop.name 
            }
          })
          codes.map(code => {
            if(code.id === allocation.codeID){
              allocation.codeName = code.codeName 
            }
          })
        })
      })  
      
      setIsLoading(false);
      return "";
    }
  }
  
  const fetchUsers = async () => {
    if(User.type == "SuperUser"){
      const getAllUsers = await getData('api/users/GetAllUsers');
      setUsers(getAllUsers);      
    }
    else{
      const getAllUsers = await getData('api/users/GetMyApprovees');
      setUsers(getAllUsers);
    }
  }
  
  const fetchUploads = async () => {
    const getAllUploads = await getData('api/users/getuploads');
    setUploads(getAllUploads.reverse())
    setUpload(getAllUploads[0]);
  }
  
  const fetchProperties = async () => {
    const getAllProperties = await getData('api/users/getproperties');
    setProperties(getAllProperties.sort((a, b) => {
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    }));
  }
  
  const fetchCodes = async () => {
    const getAllCodes = await getData('api/users/getcodes');
    setCodes(getAllCodes);
  }

  const handleApproveAllExpenses = async () => {
    const response = await postData(`api/users/UpdateApproveMassExpense`, massApproveArr)
    if(response === "Success"){
      const tempExpArr = [...expenses];
      const tempExpArrAll = [...allExpenses];
      for(let i = 0; i < massApproveArr.length; i++){
        const index = expenses.findIndex(expense => expense.id === massApproveArr[i]);
        tempExpArr[index].approved = true;
        const indexAll = allExpenses.findIndex(expense => expense.id === massApproveArr[i]);
        tempExpArrAll[indexAll].approved = true;
      }
      setExpenses(tempExpArr);
      setAllExpenses(tempExpArrAll);
    }
  }

  const handleUpdateExpense = (e, index) => {
    index = index ? index : editIndex;
    const tempExpArr = [...expenses];
    tempExpArr[index][e.target.name] = e.target.value;
    setExpenses(tempExpArr);
  }

  const handleSortHolder = (e) => {
    const selectProp = document.getElementById('sort-property');
    selectProp.value = "";
    massApproveArr = []
    if(e.target.value == ""){
      setExpenses(allExpenses);
      setShowApproveAllBtn(false);
      return "";
    }
    const tempArr = []
    allExpenses.map(expense => {
      if(expense.userID == e.target.value){
        tempArr.push(expense);
      }
    })

    tempArr.sort((a, b) => {
      return (a.approved === b.approved) ? 0 : a.approved ? 1 : -1 })

    setExpenses(tempArr);
    setShowApproveAllBtn(true);
  }

  const handleSortProperty = (e) => {
    const selectHolder = document.getElementById('sort-holder');
    selectHolder.value = "";
    massApproveArr = []
    setShowApproveAllBtn(false);
    if(e.target.value == ""){
      setExpenses(allExpenses);
      return "";
    }
    const tempArr = []
    allExpenses.map(expense => {
      if(expense.propertyID == e.target.value){
        tempArr.push(expense);
      }
    })

    setExpenses(tempArr);
  }

  const addExpense = (num) =>{
    if(!massApproveArr.includes(num)){
      massApproveArr.push(num);
    }
  }

  return (
    <div className="body-page dashboard-page">
      <DashboardHeader />
      <div className="dashboard-body dashboard-body-navbar row">
        <div className="approve-expense-body">
          <h1 className='dashboard-h1'>Approve Expenses</h1>
          <div id='search-sort-bar' className="search-sort flex-row-wrap">
             <select className='sort-select' onChange={handleChangeUpload}>
              {
                uploads.map((upload, idx) => {
                  if(idx === 0){
                    return <option defaultValue key={upload} value={upload}>{upload}</option>
                  }
                  else{
                    return <option key={upload} value={upload}>{upload}</option>
                  }
                })
              }
            </select>
            <select id='sort-property' className='sort-select' onChange={handleSortProperty}>
              <option value="">Choose Property...</option>
              {
                properties.map(property => {
                  return (
                    <option key={`card_${property.id}`} value={property.id}>{property.name}</option>
                )})
              }
            </select>
            <select id="sort-holder" className='sort-select' onClick={handleSortHolder}>
              <option value="">All Card Holders</option>
              {
                users.map(user => (
                  <option key={`user_${user.userID}`} value={user.userID}>{user.name}</option>
                ))
              }
            </select>
            {
              showApproveAllBtn &&
              <button className='btn btn-approve mass-approve' onClick={handleApproveAllExpenses}>Approve All</button>
            }
          </div>
          <div className="approve-table">
            <div className="tr expense-row approve-row">
              <div className="th td">Date</div>
              <div className="th td">Card Holder</div>
              <div className="th td">Detail</div>
              <div className="th td">Property</div>
              <div className="th td">GL Code</div>
              <div className="th td">Amount</div>
              <div className="th td">Type</div>
              <div className="th td">Approve</div>
            </div>
            <div className="table-body expense-table">
              {
                !isLoading ? 
                expenses.map((expense, index) => {
                  if(expense.allocations.length === 0){
                      return <ApproveExpenseRow 
                        key={expense.id} 
                        expense={expense} 
                        index={index}
                        setEditIndex={() => setEditIndex(index)}
                        handleUpdateExpense={handleUpdateExpense}
                        addExpense={addExpense}
                      />
                  }
                })
                :
                <div className="spinner-box">
                  <Spinner />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApproveExpense