import React, { useState, useEffect } from 'react';
import { GrClose } from "react-icons/gr";
import { BiTrash } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import CardHolderModal from './CardHolderModal';
import ApproversModal from './ApproversModal';



function ApproversRow({approver, users, deleteApprover, setChange, nonApprovers}) {
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(true);
  const [addCard, setAddCard] = useState(true);

  const addCardFunc = () => {
    const addInput = document.getElementById("add-card-input");
    setAddCard(false);    
  }
  
  return (
    <div>
      <div className="tr approvers-table" onClick={() => setShowDetails(true)}>
        <div className="th td">{approver.name}</div>  
        <div className="td">
          {approver.approvees.map((approvee, idx) => {
            if(!approvee){
              nonApprovers.map(nonApprover => {
                if(nonApprover.approverID === approver.userID){
                  approvee = nonApprover
                }
              })
            }
            return <div className="approvee">{approvee?.name}</div>
          })}  
        </div> 
      </div>
      {
        showDetails
        ?
          <ApproversModal
            key={`ApproverModal${approver.userID}`}
            approver={approver} 
            users={users} 
            closeModal={() => setShowDetails(false)} 
            deleteApprover={deleteApprover} 
            setChange={setChange}
          />
        :
        <></>
      }
    </div>
  )
}

export default ApproversRow