import React, { useState, useEffect } from 'react';
import { GrClose } from "react-icons/gr";
import { BiTrash } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import PropTypes from 'prop-types';
import { postData } from '../Data/Data';


function CardHolderModal({
  holder, 
  handleChangeUser, 
  closeModal, 
  handleSaveUser, 
  startEdit,
  approvers,
  handleAddCard,
  newUser,
  handleDeleteCard,
  handleDeleteProperty,
  properties,
  addUserProperty,
  isProfile,
}) {
  const [editDetails, setEditDetails] = useState(startEdit);
  const [newCard, setNewCard] = useState("");
  const [newProperty, setNewProperty] = useState({});
  const addCardFunc = () => {
    handleAddCard(newCard.replace(/\s/g, ""), newUser);
    setNewCard("");
  }

  const addPropertyFunc = () => {
    if(newProperty !== {}){
      addUserProperty(newProperty, newUser);
      if(!newUser){
        const response = postData("api/users/AddUserProperty", {userID: holder.userID, propertyID: newProperty.id})
      }
    }
  }
  
  const deleteCard = (idx, id) => {
    if(!newUser){
      const response = postData("api/users/deletecard", {ID: id})
    }
    handleDeleteCard(idx, newUser);
  } 
  
  const deleteProperty = (idx, id) => {
    if(!newUser){
      const response = postData("api/users/deleteuserproperty", {userID: holder.userID, propertyID: id})
    }
    handleDeleteProperty(idx, newUser);
  } 

  return (
    <div className='popup-box'>
      <div className="overlay card-holder-details" onClick={() => closeModal()}></div>
      <div className="details-card">
        <GrClose className='close-btn' onClick={() => closeModal()} />
        <h3>Card Holder</h3>
        <div className="data-point">
          <label className="profile-label">Name:</label>
          {
            editDetails
            ?
            <input
              placeholder='John Doe' 
              type="text" 
              className='edit-details' 
              id="name"
              value={holder.name}                
              onChange={handleChangeUser}
            />
            :
            <div className="profile-data">{holder.name}</div>
          }
        </div>
        <div className="data-point">
          <label className="profile-label">Email:</label>
          {
            editDetails
            ?
            <input 
              placeholder='john@doe.com' 
              type="text" className='edit-details' 
              value={holder.email}
              id="email"
              onChange={handleChangeUser}
              />
            :
            <div className="profile-data">{holder.email}</div>
          }
        </div>
        <div className="data-point">
          <label className="profile-label">Username:</label>
          {
            editDetails
            ?
            <input 
              placeholder='jdoe' 
              type="text" className='edit-details' 
              value={holder.username}
              id="username"
              onChange={handleChangeUser}
              />
            :
            <div className="profile-data">{holder.username}</div>
          }
        </div>
        <div className="data-point">
          <label className="profile-label">Password:</label>
          {
            editDetails
            ?
            <input 
              placeholder='****' 
              type="password" 
              className='edit-details' 
              value={holder.password}
              id="password"
              onChange={handleChangeUser}
              />
            :
            <div className="profile-data">xxxx</div>
          }
        </div>
        <div className="data-point">
          <label className="profile-label">Phone:</label>
          {
            editDetails
            ?
            <input 
              placeholder='xxx-xxx-xxxx' 
              type="text" className='edit-details' 
              value={holder.phone}
              id="phone"
              onChange={handleChangeUser}
              />
            :
            <div className="profile-data">{holder.phone}</div>
          }
        </div>
        {
          !isProfile &&
          <>
            <div className="data-point">
              <label className="profile-label">Position:</label>
              {
                editDetails
                ?
                <input 
                  placeholder='Property Manager' 
                  type="text" className='edit-details' 
                  value={holder.position}
                  id="position"
                  onChange={handleChangeUser}
                  />
                :
                <div className="profile-data">{holder.position}</div>
              }
            </div>
            <div className="data-point">
              <label className="profile-label profile-label-width">Spending Limits?:</label>
              {
                editDetails
                ?
                <input 
                  type="Checkbox" 
                  className='edit-details edit-details-checkbox' 
                  id="spendingLimits"
                  onChange={handleChangeUser}
                  value={holder.spendingLimits == null ? false : holder.spendingLimits} 
                  checked={holder.spendingLimits == null ? false : holder.spendingLimits}
                />
                :
                <div className="profile-data">{holder.spendingLimits ? "Yes" : "No"}</div>
              }
            </div>
            <div className="data-point">
              <label className="profile-label profile-label-width">Approval Needed?:</label>
              {
                editDetails
                ?
                <input 
                  type="Checkbox" 
                  className='edit-details edit-details-checkbox' 
                  id="approvalNeeded"
                  onChange={handleChangeUser}
                  value={holder.approvalNeeded == null ? false : holder.approvalNeeded} 
                  checked={holder.approvalNeeded == null ? false : holder.approvalNeeded}
                />
                :
                <div className="profile-data">{holder.approvalNeeded ? "Yes" : "No"}</div>
              }
            </div>
            <div className="data-point">
              <label className="profile-label profile-label-width">Require Receipts?:</label>
              {
                editDetails
                ?
                <input 
                  type="Checkbox" 
                  className='edit-details edit-details-checkbox' 
                  id="requireReceipts"
                  onChange={handleChangeUser}
                  value={holder.requireReceipts}
                  checked={holder.requireReceipts}
                />
                :
                <div className="profile-data">{holder.requireReceipts ? "Yes" : "No"}</div>
              }
            </div>
            <div className="data-point">
              <label className="profile-label profile-label-width">Active?:</label>
              {
                editDetails
                ?
                <input 
                  type="Checkbox" 
                  className='edit-details edit-details-checkbox' 
                  id="active"
                  onChange={handleChangeUser}
                  value={holder.active} 
                  checked={holder.active}
                />
                :
                <div className="profile-data">{holder.active ? "Yes" : "No"}</div>
              }
            </div>
            <div className="data-point">
              <label className="profile-label">Approver:</label>
              {
                editDetails
                ?
                <select 
                  name="approverID"
                  id="approverID"
                  onChange={handleChangeUser}
                  value={holder.approverID}
                >
                  <option value={0}>Choose...</option>
                  {
                    approvers.map(approver => {
                      return (
                      <option 
                        key={`approver_${approver.userID}`}
                        value={approver.userID}
                      >
                        {approver.name}
                      </option>)
                    })
                  }
                </select>

                :
                <div className="profile-data">{holder.approverName}</div>
              }
            </div>
          </>
        }
        {/* <div className="data-point">
          <label className="profile-label">Properties:</label>
          <div className="properties-list">
          {
            holder.properties.map((property, idx) => {
              return (
              <div key={`property${property.id}_${idx}`} className="profile-card">
                <div className='property'>{property.name}
                {
                  editDetails
                  &&
                  <BiTrash className='trash-it margin-right-small' onClick={() => deleteProperty(idx, property.id)} />
                }                  
                </div>
              </div>)
            })
          }
          {
            editDetails
            &&
            <div className='add-property'>
              <select onChange={handleChangeProperty} >
                <option value="">Choose...</option>
                {
                  properties.map(property => {
                    return (
                    <option 
                      key={property.id}
                      value={property.id}
                    >
                      {property.name}
                    </option>)
                  })
                }
              </select>
              <div className="add-card">
                <BsPlusLg className='add-btn properties-add' onClick={addPropertyFunc}/>
              </div>
            </div>
          }
          </div>
        </div> */}
        {
          !isProfile &&
          <>
            <div className="data-point">
              <label className="profile-label">Type:</label>
              <div className="properties-list">
              {
                editDetails
                ?
                <div className='add-property'>
                  <select
                  name="type"
                  id="type"
                  onChange={handleChangeUser}
                  value={holder.type}
                  >
                    <option value="">Choose...</option>
                    <option value="User">User</option>
                    <option value="Accountant">Accountant</option>
                    <option value="SuperUser">Super User</option>
                  </select>
                </div>
                :
                <div className="profile-data">{holder.type}</div>
              }
              </div>
            </div>
            {
              !newUser && 
              <>
                  <div className="data-point">
                    <label className='profile-label'>Credit Cards: </label>
                    <div className="profile-data">
                      <div className="profile-card">
                        {
                          holder.cards.map((card, idx) => {
                            return <div key={card.id} className="profile-card">
                              <div className="card-num" key={`Card_trash_${idx}`}>
                                {card.number}
                                {
                                  editDetails
                                  &&
                                  <BiTrash className='trash-it' onClick={() => deleteCard(idx, (newUser ? null : card.id))} />
                                }
                                
                              </div>
                            </div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                  {
                    editDetails
                    &&
                    <div className="add-card flex-row-wrap margin-bottom-small">
                      <input 
                        type="text" 
                        className='edit-details add-card-input' 
                        id='add-card-input'
                        value={newCard}
                        onChange={(e) => {
                          setNewCard((prevState) => (
                            e.target.value
                          ))
                        }} 
                      />
                      <BsPlusLg className='add-btn' onClick={addCardFunc}/>
                    </div>
                  }
              </>

            }
          </>
        }
        <button 
          className="btn btn-primary btn-full-width" 
          onClick={() => {
            setEditDetails(!editDetails)
            editDetails
            &&
            handleSaveUser()
          }}
        >
          {editDetails ? "Save" : "Edit"}
        </button>
      </div>
    </div>
  )
}

CardHolderModal.defaultProps = {
  startEdit: false,
  newUser: false
};

CardHolderModal.propTypes = {
  startEdit: PropTypes.bool,
  newUser: PropTypes.bool
};

export default CardHolderModal