import React, { useState } from 'react';
import { postData } from '../Data/Data';
import GLModal from './GLModal';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";

function GLCodesRow({
  code,
  groups,
  setEditIndex,
  handleChangeCode,
}) {
  const [showModal, setShowModal] = useState(false);

  const handleSaveCode = () => {
    const response = postData("api/users/updatecode", code);
    setShowModal(false);
  }
  return (
    <div>
      <div className='gl-code-row' onClick={() => {
          setEditIndex()
          setShowModal(true)
        }}
        >
        <div className='gl-text'>{code.codeName}</div>
        <div className='gl-text'>{code.group?.groupName}</div>
        <div className="td center">
          {
            code.active === true
            ?
            <AiOutlineCheckCircle className='approve-expence-btn approved' /> 
            :
            code.active === false
            ?
            <TiDeleteOutline className='approve-expence-btn denied' />
            :
            ""
          }
        </div>

      </div>
      {
        showModal
        &&
        <GLModal 
          code={code} 
          closeModal={() => setShowModal(false)} 
          groups={groups}
          handleChangeCode={handleChangeCode}
          handleSaveCode={handleSaveCode}
        />
      }
    </div> 
  )
}

export default GLCodesRow