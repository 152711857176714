import React, { useState } from 'react';
import DashboardHeader from '../components/DashboardHeader';
import {Link, useNavigate} from 'react-router-dom';
import { useEffect } from 'react';
import { getData, patchData, User } from '../Data/Data';
import CardHolderModal from '../components/CardHolderModal';

function MyProfile() {
  const [showNewModal, setShowNewModal] = useState(false);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  
  useEffect(async () => {
    const getUser = await getData(`api/users/${User.userID}`);
    setUser(getUser);
  }, [])
  
  const handleChangeUser = (e) => {
    setUser((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value === "true" ? false : e.target.value === "false" ? true : e.target.value,
    }))
  }

  const handleSaveUser = async () => {
    const response = await patchData('api/auth/UpdateUser', user);
    setShowNewModal(false);
  }

  return (
    <div className="body-page">
      <DashboardHeader />
      <div className="dashboard-body">
        <h1 className='profile-header'>My Profile</h1>
        <div className="profile-body">
          <div className="data-point">
            <label className='profile-label'>Name: </label>
            <div className="profile-data">{user.name}</div>
          </div>
          <div className="data-point">
            <label className='profile-label'>Email: </label>
            <div className="profile-data">{user.email}</div>
          </div>
          <div className="data-point">
            <label className='profile-label'>Cell: </label>
            <div className="profile-data">{user.phone}</div>
          </div>
          {/* <div className="data-point">
            <label className='profile-label'>Credit Cards: </label>
            <div className="profile-data">
              {
                user.cards.map(card => {
                  return <div key={card.id} className="profile-card">
                      {card.cardNum}
                      <bf />
                    </div>
                })
              }
            </div>
          </div> */}
          <button 
            className="btn btn-primary margin-top-xl" 
            onClick={() => setShowNewModal(true)}
          >
            Edit Account
          </button>
        </div>
      </div>
      {
        showNewModal
        ?
        <CardHolderModal 
          holder={user} 
          handleChangeUser={handleChangeUser}
          closeModal={() => setShowNewModal(false)}
          handleSaveUser={handleSaveUser}
          startEdit={true}
          newUser={false}
          isProfile={true}
        /> 
        :
        <></>
      }
    </div>
  )
}

export default MyProfile