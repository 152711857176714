import React, { useEffect, useState } from 'react';
import CardHolderRow from '../components/CardHolderRow'
import DashboardHeader from '../components/DashboardHeader'
import Navbar from '../components/Navbar'
import { GiHamburgerMenu } from "react-icons/gi";
import CardHolderModal from '../components/CardHolderModal';
import ApproversModal from '../components/ApproversModal';
import ApproversRow from '../components/ApproversRow';
import { getData, postData } from '../Data/Data';

function CardHolderApprovers() {
  const [showNewModal, setShowNewModal] = useState(false);
  const [nonApprovers, setNonApprovers] = useState([]);
  const [newApprovers, setNewApprovers] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [newApprover, setNewApprover] = useState({approvees: []});
  const [editIndex, setEditIndex] = useState();
  const [change, setChange] = useState(0);

  useEffect(async () => {
    const tempApprovers = await getData("api/users/GetApprovers");
    setApprovers(tempApprovers);
    const tempNonApprovers = await getData("api/users/GetUnassignedApprovers")
    setNonApprovers(tempNonApprovers);
    const tempNewApprovers = await getData("api/users/GetNonApprovers")
    setNewApprovers(tempNewApprovers);
  }, [change])

  const handleSave = async (id) => {
    const response = await postData("api/users/SetApprover", {UserID: id, IsApprover: true})
    setChange(change + 1);
  }

  const dispayNav = () => {
    const navbar = document.getElementById('navbar')
    navbar.style.display = 'flex';
  }

  const deleteApprover = async (id) => {
    const response = await postData("api/users/SetApprover", {UserID: id, IsApprover: false})
    setChange(change + 1);
  }

  return (
    <div className="body-page dashboard-page">
      <div className="hamburger">
       <GiHamburgerMenu className='hamberger-icon' onClick={() => dispayNav()} />   
      </div>
      <DashboardHeader />
      <div className="dashboard-body dashboard-body-navbar row">
        <Navbar />
        <div className="dashboard-body-content">
          <h1 className='dashboard-h1'>Manage Approvers</h1>
          <button className="btn btn-primary btn-quarter-width margin-bottom-xl" onClick={() => setShowNewModal(true)}>Add Approver</button>
          <div className="table approvers-table">
            <div className="tr">
              <div className="th td">Approver</div>  
              <div className="th td">Approvees</div>
            </div>
            {
              approvers.map((approver, idx) => {
                return (
                <ApproversRow 
                  key={`ApproverRow_${idx}`} 
                  approver={approver} 
                  users={nonApprovers} 
                  nonApprovers={newApprovers}
                  deleteApprover={deleteApprover}
                  setChange={() => setChange(change + 1)}
                />)
              })
            }
          </div>
        </div>
      </div>
      {
        showNewModal
        ?
        <ApproversModal 
          key={"New_Approver"}
          handleSave={handleSave} 
          approver={newApprover} 
          users={nonApprovers} 
          newApprovers={newApprovers}
          closeModal={() => setShowNewModal(false)} 
          newApprover={true}
          setChange={() => setChange(change + 1)}
        />
        :
        <></>
      }
    </div>
  )
}

export default CardHolderApprovers