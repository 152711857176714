import React, { useState } from 'react';
import CardHolderModal from './CardHolderModal';
import { patchData } from '../Data/Data';



export default function CardHolderRow({
  holder, 
  handleChangeUser, 
  setEditIndex,
  approvers,
  handleChangeApprover,
  handleAddCard,
  handleDeleteCard,
  addUserProperty,
  handleDeleteProperty,
  properties,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [addCard, setAddCard] = useState(true);

  const addCardFunc = () => {
    const addInput = document.getElementById("add-card-input");
    setAddCard(false);
    holder.cards.push({id: 12, cardNum: addInput.value})
  }

  const handleSaveUser = async () => {
    const response = await patchData('api/auth/UpdateUser', holder);
    handleChangeApprover(response.approverName);
  }
  
  return (
    <div>
      <div 
        className="tr manage-card-holders-table" 
        onClick={() => {
          setEditIndex()
          setShowDetails(true)
        }}>
        <div className="th td">{holder.name}</div>  
        <div className="th td">{holder.email}</div>  
        <div className="th td">{holder.position}</div>  
        <div className="th td">
          {holder.cards.map(card => {
            return <div>{card.number}</div>
          })}
        </div>
        <div className="th td">{holder.approvalNeeded ? "Yes" : "No"}</div>
        <div className="th td">{holder.spendingLimits ? "Yes" : "No"}</div>
      </div>
      {
        showDetails
        ?
          <CardHolderModal 
            holder={holder} 
            closeModal={() => {setShowDetails(false)}} 
            handleChangeUser={handleChangeUser}
            handleSaveUser={handleSaveUser}
            approvers={approvers}
            handleAddCard={handleAddCard}
            handleDeleteCard={handleDeleteCard}
            handleDeleteProperty={handleDeleteProperty}
            addUserProperty={addUserProperty}
            properties={properties}
          />
        :
        <></>
      }
    </div>
  )
}

