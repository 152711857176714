import React, { useEffect, useState } from 'react';
import CardHolderRow from '../components/CardHolderRow'
import DashboardHeader from '../components/DashboardHeader'
import Navbar from '../components/Navbar'
import { GiHamburgerMenu } from "react-icons/gi";
import CardHolderModal from '../components/CardHolderModal';
import { getData, postData } from '../Data/Data';

function ManageCardHolders() {
  const [showNewModal, setShowNewModal] = useState(false);
  const [cardHolders, setCardHolders] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [properties, setProperties] = useState([]);
  const [editIndex, setEditIndex] = useState();
  const emptyCardHolder = {
    name: '',
    email: '',
    username: '',
    password: '',
    Phone: '',
    position: '',
    properties: [],
    userProperties: [],
    cards: [],
    spendingLimits: true,
    approvalNeeded: true,
    requireReceipts: true,
    approver: null,
    active: true,
    isApprover: false,
  }
  const [newCardHolder, setNewCardHolder] = useState(emptyCardHolder);

  useEffect(async () => {
    const getHolders = await getData("api/users/getusers");
    const tempApprovers = await getData("api/users/GetApprovers");
    const tempProperties = await getData("api/users/getproperties");
    setCardHolders(getHolders);    
    setApprovers(tempApprovers);
    setProperties(tempProperties);

  }, [])

  const handleChangeUser = (e) => {
    setNewCardHolder((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value === "true" ? false : e.target.value === "false" ? true : e.target.value,
    }))
  }

  const addUserProperty = (property, newUser) => {
    if(newUser){
      const tempCardHolder = {...newCardHolder}
      tempCardHolder.properties.push(property);
      setNewCardHolder(tempCardHolder);
    }
    else{
      const tempCardHolders = [...cardHolders];
      tempCardHolders[editIndex]["properties"].push(property);
      setCardHolders(tempCardHolders);
    }
  }

  const handleUpdateUserUser = (e) => {
    const tempCardHolders = [...cardHolders];
    if((tempCardHolders[editIndex][e.target.id] === null || tempCardHolders[editIndex][e.target.id] === "") && (e.target.id === "spendingLimits" || e.target.id === "approvalNeeded")){
      tempCardHolders[editIndex][e.target.id] = true;
    }
    if(e.target.id === "approverID"){
      approvers.map(approver => {
        if(approver.userID == e.target.value){
          tempCardHolders[editIndex]['approverName'] = approver.name;

        }
      })
    }
    tempCardHolders[editIndex][e.target.id] = e.target.value === "true" ? false : e.target.value === "false" ? true : e.target.value;
    setCardHolders(tempCardHolders);

  }

  const deleteNewCard = (index, newUser) => {
    if(newUser){
      const tempCardHolder = {...newCardHolder}
      let cardArr = []
      tempCardHolder.cards.map((card, idx) => {
        if(idx !== index){
          cardArr.push(card);
        }
      })
      tempCardHolder.cards = cardArr;
      setNewCardHolder(tempCardHolder);
    }
    else{

      const tempCardHolders = [...cardHolders];
      let cardArr = []
      tempCardHolders[editIndex]["cards"].map((card, idx) => {
        if(idx !== index){
          cardArr.push(card);
        }
      })
      tempCardHolders[editIndex]["cards"] = cardArr;
      setCardHolders(tempCardHolders);

    }

  }

  const deleteNewProperty = (index, newUser) => {
    if(newUser){
      const tempCardHolder = {...newCardHolder}
      let propertyArr = []
      tempCardHolder.properties.map((card, idx) => {
        if(idx !== index){
          propertyArr.push(card);
        }
      })
      tempCardHolder.properties = propertyArr;
      setNewCardHolder(tempCardHolder);
    }
    else{

      const tempCardHolders = [...cardHolders];
      let propertyArr = []
      tempCardHolders[editIndex]["properties"].map((card, idx) => {
        if(idx !== index){
          propertyArr.push(card);
        }
      })
      tempCardHolders[editIndex]["properties"] = propertyArr;
      setCardHolders(tempCardHolders);

    }

  }

  const handleChangeApprover = (approverName) => {
    const tempCardHolders = [...cardHolders];
    tempCardHolders[editIndex]["approverName"] = approverName;
    setCardHolders(tempCardHolders);
  }

  const handleAddCard = (newCard, newUser) => {
    if(newCard != ""){
      if(newUser){
        const tempCardHolder = {...newCardHolder}
        tempCardHolder.cards.push({number: newCard});
        setNewCardHolder(tempCardHolder);
      }
      else{
        const tempCardHolders = [...cardHolders];
        tempCardHolders[editIndex]["cards"].push({number: newCard});
        setCardHolders(tempCardHolders);
      }
    }
  }

  const addNewHolder = async () => {
    const saveUser = await postData("api/auth/register", newCardHolder);
    await setNewCardHolder(emptyCardHolder);
    await setCardHolders(state => [newCardHolder, ...state]);
    setShowNewModal(false);
  }

  const dispayNav = () => {
    const navbar = document.getElementById('navbar')
    navbar.style.display = 'flex';
  }

  return (
    <div className="body-page dashboard-page">
      <div className="hamburger">
       <GiHamburgerMenu className='hamberger-icon' onClick={() => dispayNav()} />   
      </div>
      <DashboardHeader />
      <div className="dashboard-body dashboard-body-navbar row">
        <Navbar />
        <div className="dashboard-body-content">
          <h1 className='dashboard-h1'>Manage Card Holders</h1>
          <button className="btn btn-primary btn-quarter-width margin-bottom-xl" onClick={() => setShowNewModal(true)}>Add New card Holder</button>
          <div className="table manage-card-holders-table table-height">
            <div className="tr">
              <div className="th td">Name</div>  
              <div className="th td">Email</div>  
              <div className="th td">Position</div>  
              <div className="th td">Card</div>
              <div className="th td">Approval?</div>
              <div className="th td">Limits?</div>
            </div>
            {
              cardHolders.map((holder, idx) => {
                return (
                  <CardHolderRow 
                    key={idx} 
                    holder={holder} 
                    setEditIndex={() => setEditIndex(idx)} 
                    handleChangeUser={handleUpdateUserUser}
                    approvers={approvers}
                    handleChangeApprover={handleChangeApprover}
                    handleAddCard={handleAddCard}
                    handleDeleteCard={deleteNewCard}
                    handleDeleteProperty={deleteNewProperty}
                    addUserProperty={addUserProperty}
                    properties={properties}
                    />)
                    
                  })
            }
          </div>
        </div>
      </div>
      {
        showNewModal
        ?
        <CardHolderModal 
          holder={newCardHolder} 
          handleChangeUser={handleChangeUser}
          closeModal={() => setShowNewModal(false)}
          handleSaveUser={addNewHolder}
          startEdit={true}
          approvers={approvers}
          handleAddCard={handleAddCard}
          newUser={true}
          handleDeleteCard={deleteNewCard}
          handleDeleteProperty={deleteNewProperty}
          properties={properties}
          addUserProperty={addUserProperty}
        /> 
        :
        <></>
      }
    </div>
  )
}

export default ManageCardHolders