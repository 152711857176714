import React from 'react';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";

function ReportRow({ 
    rowContent, 
  }) {
  const { 
    id,
    description, 
    displayAmount, 
    approved, 
    ccNumber, 
    expense_Date, 
    notes, 
    property, 
    code,
    spendingLimit,
    allocations,
  } = rowContent;
  // console.log(rowContent);

  const thisDate = new Date(expense_Date);

  return (
    <div>
      <div 
        key={id} 
        className="tr expense-row reports-row" 
      >        
        <div className="td date">{`${thisDate.getMonth() + 1}/${thisDate.getDate()}/${thisDate.getFullYear()}`}</div>
        <div className="td expense-desc description">{description}</div>
        <div className="td ccnumber">{ccNumber}</div>
        <div className="td hidden-lable amount">${displayAmount.toFixed(2).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
        <div className="td prop-code-select property">{property && property.name}</div>
        <div className="td prop-code-select code">{code && code.codeName}</div>
        <div className="td limit">{spendingLimit && spendingLimit.name}</div>
        <div className="td center approveddenied">
          {
            approved === true
            ?
            <AiOutlineCheckCircle className='approve-expence-btn approved' /> 
            :
            approved === false
            ?
            <TiDeleteOutline className='approve-expence-btn denied' />
            :
            ""
          }
        </div>
        <div className="td expensed-by margin-top-small spender"><b>By: </b>{rowContent.cardHolderName}</div>
        <div className="td approve-message margin-top-small message"><b>Message: </b>{notes}</div>
        {
          allocations &&
          allocations.map((allo, idx) => {
            return (
            <>
              <div className={idx === 0 ? 'allocation-start allocation-text top-allocation' : 'allocation-start allocation-text'}>${allo.displayAmount}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation' : 'allocation-text'}>{allo.propertyName}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation' : 'allocation-text'}>{allo.codeName}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation' : 'allocation-text'}>{allo.limitName}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation td center' : 'allocation-text td center'}>
                {
                  allo.approved === true
                  ?
                  <AiOutlineCheckCircle className='approve-expence-btn approved' /> 
                  :
                  allo.approved === false
                  ?
                  <TiDeleteOutline className='approve-expence-btn denied' />
                  :
                  ""
                }
              </div>
            </>
          )})
        }
      </div>
    </div>

  )
}

export default ReportRow