import React, { useState } from 'react';
import CardHolderRow from '../components/CardHolderRow'
import DashboardHeader from '../components/DashboardHeader'
import Navbar from '../components/Navbar'
import { GiHamburgerMenu } from "react-icons/gi";
import CardHolderModal from '../components/CardHolderModal';
import axios from "axios";

import { addFile, baseUrl, postFile } from '../Data/Data';
import Spinner from '../components/Spinner';

function Reports() {
  const [draggingOver, setDraggingOver] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const [loadingReceipts, setLoadingReceipts] = useState(false);
  const [expenseUploads, setExpenseUploads] = useState([]);

  const dispayNav = () => {
    const navbar = document.getElementById('navbar')
    navbar.style.display = 'flex';
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    setDraggingOver(true);
  }

  const handleDragEnd = (e) => {
    e.preventDefault();
    setDraggingOver(false);
  }
  
  const handleDrop = (e) => {
    e.preventDefault();
    if(e.dataTransfer.files.length){
      setUploadedFile(e.dataTransfer.files[0]) 
    }
    setDraggingOver(false);
  }

  const handleSubmitFile = async (e) => {
    setExpenseUploads([]);
    setLoadingReceipts(true);
    const data = new FormData;
    data.append("FileFile", uploadedFile);
    data.append("FileName", "ExceFile");
    const response = await addFile(data);
    await axios.post(`${baseUrl}api/users/ProcessImports`, data)
    .then(res => {
      setUploadedFile(null);
      setExpenseUploads(res.data);
      setLoadingReceipts(false);
    })
  }

  return (
    <div className="body-page dashboard-page">
      <div className="hamburger">
       <GiHamburgerMenu className='hamberger-icon' onClick={() => dispayNav()} />   
      </div>
      <DashboardHeader />
      <div className="dashboard-body dashboard-body-navbar row">
        <Navbar />
        <div className="dashboard-body-content reports-body">
          <div className="reports-header">
            <h1 className='dashboard-h1'>Imports Receipts</h1>
            <h4>Upload file with expenses starting on the second row with Date, Description, Accout#/CC#, then Amount in that order</h4>
            <div 
              className={draggingOver ? "drop-zone--over drop-zone" : "drop-zone"} 
              onDragOver={handleDragOver}
              onDragEnd={handleDragEnd}
              onDragLeave={handleDragEnd}
              onDrop={handleDrop}
              >
                {
                  uploadedFile
                  ?
                  <div className="drop-zone__thump">
                    <div className="drop-zone__thump-title">{uploadedFile.name}</div>
                  </div>
                  :
                  <span className="drop-zone__prompt">Drop file here or click to upload</span>
                }
            </div>
            {
              uploadedFile
              &&
              <button 
              type='button'
                className="btn btn-primary margin-bottom-small margin-top-small btn-full-width"
                onClick={handleSubmitFile}
              >
                Submit
              </button>
            }
          </div>
          {
            expenseUploads.length > 0 && 
            <div className="imports-row imports-row-header">
              <div className="td">
                Date
              </div>
              <div className="td">
                Description
              </div>
              <div className="td">
                Card Number
              </div>
              <div className="td">
                Card Holder
              </div>
              <div className="td amount-number">
                Amount
              </div>
            </div>

          }
          <div className="reports-content">
            {
              loadingReceipts ?
              <div className="spinner-box">
                <Spinner />
              </div>
              :
              expenseUploads.map((expense, idx) => {
                const expenseDate = new Date(expense.expense_Date);
                return <div key={`expense_${expense.receiptID}_${idx}`} className="imports-row">
                  <div className="td">
                    {`${expenseDate.getMonth()}/${expenseDate.getDate()}/${expenseDate.getFullYear()}`}
                  </div>
                  <div className="td">
                    {expense.description}
                  </div>
                  <div className="td">
                    {expense.ccNumber}
                  </div>
                  <div className="td">
                    {expense.cardHolderName}
                  </div>
                  <div className="td amount-number">
                    {expense.amount && (expense.amount / 100).toFixed(2)}
                  </div>
                </div>
              })
            }
          </div>
          <p className='import-success'>
            The above {expenseUploads.length} receipts were added successfully! 
            {
              expenseUploads.length > 0 &&
              <b> <a href={`mailto:${expenseUploads[0].notes}?subject=New Expenses Uploaded&body=Hi,%0d%0a %0d%0aThis is to inform you that you new expenses loaded into the expense tracking system.%0d%0a %0d%0aThanks,%0d%0aAngie%0d%0a %0d%0a`}>Email Card Holders!!</a></b>
            }
          </p>
        </div>
      </div>
    </div>
  )
}

export default Reports