import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ApproveExpense from './pages/ApproveExpense';
import CardHolderApprovers from './pages/CardHolderApprovers';
import Dashboard from './pages/Dashboard';
import ForgotPassword from './pages/ForgotPassword';
import GLCodes from './pages/GLCodes';
import ImportExport from './pages/ImportExport';
import Login from './pages/Login';
import ManageCardHolders from './pages/ManageCardHolders';
import MyProfile from './pages/MyProfile';
import Properties from './pages/Properties';
import Reports from './pages/Reports';
import ResetPasswordDashboard from './pages/ResetPasswordDashboard';
import ResetPasswordLogin from './pages/ResetPasswordLogin';
import SpendingLimits from './pages/SpendingLimits';
import { User } from './Data/Data';
import Groups from './pages/Groups';
import Search from './pages/Search';

function App() {
  return (
  <>
    <Router>
      <Routes>
        <Route exact path='/' element={<Login />} />
        <Route exact path='/Forgot-Password' element={<ForgotPassword />} />
        <Route exact path='/Password-Reset-Login' element={<ResetPasswordLogin />} />
        <Route exact path='/Dashboard' element={<Dashboard />} />
        <Route exact path='/Dashboard/Reset-Password' element={<ResetPasswordDashboard />} />
        <Route exact path='/Dashboard/Manage-Card-Holders' element={<ManageCardHolders />} />
        <Route exact path='/My-Profile' element={<MyProfile />} />
        <Route exact path='/Manage-Card-Holders' element={<ManageCardHolders />} />
        <Route exact path='/Card-Holder-Approvers' element={<CardHolderApprovers />} />
        <Route exact path='/Reports' element={<Reports />} />
        <Route exact path='/Properties' element={<Properties />} />
        <Route exact path='/GL-Codes' element={<GLCodes />} />
        <Route exact path='/Groups' element={<Groups />} />
        <Route exact path='/Import-Export' element={<ImportExport />} />
        <Route exact path='/Spending-Limits' element={<SpendingLimits />} />
        <Route exact path='/Search' element={<Search />} />
        <Route exact path='/Approve-Expenses' element={<ApproveExpense />} />
      </Routes>
    </Router>
  </>
  );
}

export default App;
