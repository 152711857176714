import React, { useState, useEffect } from 'react';
import { GrClose } from "react-icons/gr";
import { BiTrash } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import PropTypes from 'prop-types';


function PropertiesModal({
  property, 
  groups, 
  closeModal, 
  deleteProperty, 
  handleSaveProperty, 
  handleChangeProperty, 
  newProperty
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [addCard, setAddCard] = useState(true);

  const addCardFunc = () => {
    const addInput = document.getElementById("add-card-input");

  }

  return (
    <div className='popup-box'>
      <div className="overlay card-holder-details" onClick={() => closeModal()}></div>
      <div className="details-card">
        <GrClose className='close-btn' onClick={() => closeModal()} />
        <h3>Property</h3>
        <div className="data-point">
          <label className="profile-label">Name:</label>
          {
            newProperty || editDetails
            ?
            <input 
              placeholder='ABC Property'
              type="text"
              className='edit-details'
              id='name'
              value={property.name}
              onChange={handleChangeProperty}
            />
            :
            <div className="profile-data">{property.name}</div>
          }
        </div>
        <div className="data-point">
          <label className="profile-label">Group:</label>
          {
            newProperty || editDetails
            ?
            <select 
              className='edit-details approver-list'
              id="groupID"
              onChange={handleChangeProperty}
              defaultValue={property.groupID}
            >
              <option value=''>Choose...</option>
              {
                groups.map(group => {
                  if(group.active){
                  return (
                    <option 
                      key={`${group.groupID}`}
                      value={group.groupID}
                    >
                      {group.groupName}
                    </option>)
                }})
              }
            </select>
            :
            <div className="profile-data">{property.group ? property.group.groupName : ""}</div>
          }
        </div>
        <div className="data-point">
          <label className="profile-label">Active:</label>
          {
            newProperty || editDetails
            ?
            <input 
              type="Checkbox" 
              className='edit-details' 
              id="active"
              onChange={handleChangeProperty}
              value={property.active} 
              checked={property.active}
            />
            :
            <div className="profile-data">{property.active ? "Yes" : "No"}</div>
          }
        </div>
        {
          newProperty || editDetails
          ?
          <button 
            className="btn btn-primary btn-full-width margin-top-small"
            onClick={() => {
              handleSaveProperty(property)
              setEditDetails(false);
            }}
          >Save</button>
          :
          <button className="btn btn-primary btn-full-width margin-top-small" onClick={() => setEditDetails(!editDetails)} >Edit</button>

        }
      </div>
    </div>
  )
}

PropertiesModal.defaultProps = {
  newProperty: false
};

PropertiesModal.propTypes = {
  newProperty: PropTypes.bool
};

export default PropertiesModal