import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

function ResetPasswordLogin() {
  const navigate = useNavigate();
  const processEmailPasswordReset = () => {
    navigate('/dashboard');
  }

  return (
    <div className='body-page flex-center'>
      <div className="card login-card">
        <h1 className='header-med'>Forgot Your Password?</h1>
        <p className='text-light'>We'll email you a link to reset your password</p>
        <div className="welcome-banner-section width-full margin-top-large margin-bottom-large">
          <label htmlFor="password" className='text-light'>New Password</label>
          <input type="password" name='password' className='underline-input width-full margin-bottom-small' />
          <label htmlFor="password" className='text-light'>Confirm Password</label>
          <input type="password" name='password' className='underline-input width-full margin-bottom-small' />
          <div className="remember-forgot">
              <div className="remember">
                <Link to='/' >Login</Link>
              </div>
            </div>
        </div>
        <button className='btn btn-primary btn-3q-width font-bold' onClick={processEmailPasswordReset}>Reset Password</button>
      </div>
    </div>
  )
}

export default ResetPasswordLogin