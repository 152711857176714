import React, { useEffect, useState } from 'react';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { BiReceipt, BiTrash } from "react-icons/bi";
import { TiDeleteOutline } from "react-icons/ti";
import { FaRegCommentDots } from "react-icons/fa";
import { BsPlusLg } from "react-icons/bs";
import axios from 'axios';
import { baseUrl, getData, postData, User } from '../Data/Data';
import spinner from '../assets/spinner.gif'

function ExpenseRow({ 
    rowContent, 
    properties, 
    codes, 
    limits, 
    handleChange,
    setEditIndex,
    handleSaveExpense,
    removeImage,
    printing,
    handleChangeAllocation,
    groups,
    deleteExpense
  }) {
  const { 
    id,
    receipts, 
    description, 
    displayAmount, 
    approved, 
    ccNumber, 
    expense_Date, 
    notes, 
    property, 
    propertyID,
    groupID,
    code,
    codeID,
    spendingLimit,
    spendingLimitID,
    allocations,
  } = rowContent;
  const [allocate, setAllocate] = useState(false);
  const [group, setGroup] = useState('0');
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [allocationTotal, setAllocationTotal] = useState(0);
  const [allocationGroup, setAllocationGroup] = useState(0);
  const [allocationProperty, setAllocationProperty] = useState(0);
  const [allocationCode, setAllocationCode] = useState(0);
  const [allocationLimit, setAllocationLimit] = useState(0);
  const [equalize, setEqualize] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageLoadingError, setImageLoadingError] = useState(false);

  const addReceipt = async () => {
    setImageLoading(true);
    const input = document.getElementById('add-receipt-input');

    const formData = new FormData(); 
    formData.append("ExpenseID", rowContent.alloParent ? rowContent.alloParent.id : id)
    formData.append("ReceiptFile", input.files[0])

    await axios.post(`${baseUrl}api/users/SaveReceipt`, formData)
    .then(res => {
      handleChange({target: { name: "receipts", value: res.data }});
      if(!res.data.filePath){
        setImageLoadingError(true);
      }else{
        setImageLoadingError(false);
      }
    })
    .catch((e) => {
      console.log(e)
      setImageLoadingError(true);
    })
    .finally(() => {
      setImageLoading(false);
    })
  }

  const handleDeleteImage = async (id, idx) => {
    const response = await getData(`api/users/DeleteReceipt/${id}`);
    removeImage(idx)
  }

  const thisDate = new Date(expense_Date);
  
  const handleSaveAllocation = async () => {

    const response = await postData(`api/users/setAllocations/${id}`, allocations);

  }

  const getAllocations = () => {
    if(rowContent.alloParent){
      rowContent.alloParent.allocations.map((allocation, idx) => {
        if(!allocation){
          rowContent.alloParent.allocations[idx] = rowContent;
        }
      })
      return rowContent.alloParent.allocations;
    }
    return allocations;
  }

  const allocateAll = (e) => {
    const tempAllocations = getAllocations();

    tempAllocations.forEach((allocation, idx) => {
      handleChangeAllocation(e, idx);
    })
  }

  const equalizeAllocations = () => {
    const tempAllocations = allocations;
    const dbAmount = Math.floor(rowContent.amount / tempAllocations.length)
    const dispAmount = dbAmount / 100;
    const remainder =  rowContent.amount - (dbAmount * tempAllocations.length);
    let total = 0

    tempAllocations.forEach((allocation, idx) => {
      const value = tempAllocations.length - 1 !== idx ? dispAmount : ((dbAmount + remainder) / 100);
      handleChangeAllocation({target: {name: "displayAmount", value }}, idx);
      total += value;
    })
    setAllocationTotal(total);
  }

  useEffect(() => {
    if(equalize) equalizeAllocations();
    calcTotalAllo();
  }, [allocations])

  const calcTotalAllo = () => {
    let tempTotalAllo = 0;
    getAllocations().map(allo => {
      tempTotalAllo += parseFloat(allo.displayAmount ? allo.displayAmount : 0);
    })
    setAllocationTotal(tempTotalAllo);
  }

  const checkProperty = () => {
    if(allocations.length > 0){
      let notSet = false;
      allocations.map(allo => {
        if(!allo.propertyID || allo.propertyID == 0){
          notSet = true;
        }
      })
      return notSet;
    }
    return !propertyID
  }

  const checkCode = () => {
    if(allocations.length > 0){
      let notSet = false;
      allocations.map(allo => {
        if(!allo.codeID || allo.codeID == 0){
          notSet = true;
        }
      })
      return notSet;
    }
    return !codeID
  }

  const checkLimit = () => {
    if(!rowContent.requireLimits){
      return false;
    }
    else if(allocations.length > 0){
      let notSet = false;
      allocations.map(allo => {
        if(!allo.spendingLimitID || allo.spendingLimitID == 0){
          notSet = true;
        }
      })
      return notSet;
    }
    return !spendingLimitID
  }

  const noReceiptAttached = () => {
    if(!rowContent.requireReceipts){
      return false;
    }
    if(rowContent.alloParent){
      return !rowContent.alloParent.receipts.length > 0;
    }
    return !receipts.length > 0
  }

  const allocationsBalance = () => {
    if(allocations.length > 0){
      if((allocationTotal - (rowContent.alloParent ? rowContent.alloParent.displayAmount : displayAmount)).toFixed(2) == 0){
        return false;
      }
      else{
        return true;
      }
    }
    return false
  }

  const requiresNote = () => {
    if(allocations.length > 0){
      let notSet = false;
      allocations.map(allo => {
        limits.map(limit => {
          if(limit.id == allo.spendingLimitID){
            notSet = allo.spendingLimitID && (limit.limit / 100) < allo.displayAmount && !notes
          }
        })
      })
      return notSet;
    }
    if(rowContent.alloParent){
      return spendingLimit && (spendingLimit.limit / 100) < displayAmount && !rowContent.alloParent.notes
    }
    return spendingLimit && (spendingLimit.limit / 100) < displayAmount && !notes
  }

  return (
    <div>
      <div 
        key={id} 
        className={!printing ? "tr expense-row" : "tr expense-row expense-row-print"} 
        onClick={() => {
          setEditIndex()
          setShowDetails(!showDetails)
        }}
      >        
        <div className="td date">{`${thisDate.getMonth() + 1}/${thisDate.getDate()}/${thisDate.getFullYear()}`}</div>
        <div className="td expense-desc description">{description}</div>
        <div className="td ccnumber">{ccNumber}</div>
        <div className="td hidden-lable amount">${displayAmount && displayAmount.toFixed(2).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
        <div className="td prop-code-select property">{property && property.name}</div>
        <div className="td prop-code-select code">{code && code.codeName}</div>
        <div className="td limit">{spendingLimit && spendingLimit.name}</div>
        <div className="td center approveddenied">
          {
            approved === true
            ?
            <AiOutlineCheckCircle className='approve-expence-btn approved' /> 
            :
            approved === false
            ?
            <TiDeleteOutline className='approve-expence-btn denied' />
            :
            ""
          }
        </div>
        {
        !printing
        &&
          <div className="td image-tags print-hide awaitings">{
            checkProperty() &&
              <div className="image-tag">Needs property</div>
            }{
              checkLimit() &&
              <div className="image-tag">Needs Type</div>
            }{
              checkCode() &&
              <div className="image-tag">Needs GL Code</div>
            }{
              noReceiptAttached() &&
              <div className="image-tag">Needs Receipt</div>
            }{
              requiresNote() &&
              <div className="image-tag">Requires Note</div>
            }{
              allocationsBalance() &&
              <div className="image-tag">Immbalanced</div>
            }
          </div>
        }
        {
        !printing
        &&
          <div className="table-font margin-top-small images">{receipts.length > 0 ? receipts.map((receipt, idx) => (
            <div className="image-tag">
              <a href={`${baseUrl.substring(0, baseUrl.length-1)}${receipt.filePath}`} target="_blank" rel="noreferrer">Image {idx+1}</a>
            </div>
          )) :
          rowContent.alloParent?.receipts.map((receipt, idx) => (
            <div className="image-tag">
              <a href={`${baseUrl.substring(0, baseUrl.length-1)}${receipt.filePath}`} target="_blank" rel="noreferrer">Image {idx+1}</a>
            </div>
        ))
          }</div>
        }
        <div className="td expensed-by margin-top-small spender"  onClick={() => setShowDetails(true)}><b>By: </b>{rowContent.cardHolderName}</div>
        <div className="td approve-message margin-top-small message"  onClick={() => setShowDetails(true)}><b>Message: </b>{notes}</div>
        {
          allocations &&
          allocations.map((allo, idx) => {
            return printing ? (
            <>
              <div className={idx === 0 ? 'printing-allocation-start allocation-text top-allocation' : 'printing-allocation-start allocation-text'}>${allo.displayAmount}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation' : 'allocation-text'}>{allo.propertyName}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation' : 'allocation-text'}>{allo.codeName}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation' : 'allocation-text'}>{allo.limitName}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation td center' : 'allocation-text td center'}>
                {
                  allo.approved === true
                  ?
                  <AiOutlineCheckCircle className='approve-expence-btn approved' /> 
                  :
                  allo.approved === false
                  ?
                  <TiDeleteOutline className='approve-expence-btn denied' />
                  :
                  ""
                }
              </div>
            </>

          )
          :
          (
            <>
              <div className={idx === 0 ? 'allocation-start allocation-text top-allocation' : 'allocation-start allocation-text'}>${allo.displayAmount}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation' : 'allocation-text'}>{allo.propertyName}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation' : 'allocation-text'}>{allo.codeName}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation' : 'allocation-text'}>{allo.limitName}</div>
              <div className={idx === 0 ? 'allocation-text top-allocation td center' : 'allocation-text td center'}>
                {
                  allo.approved === true
                  ?
                  <AiOutlineCheckCircle className='approve-expence-btn approved' /> 
                  :
                  allo.approved === false
                  ?
                  <TiDeleteOutline className='approve-expence-btn denied' />
                  :
                  ""
                }
              </div>
            </>
          )
        })
        }
      </div>
      {
        showDetails
        ?
        <div className='popup-box'>
          <div className="overlay card-holder-details" onClick={() => setShowDetails(!showDetails)}></div>
          <div className="details-card expense-popup">
            <GrClose className='close-btn' onClick={() => setShowDetails(!showDetails)} />
            <h3>{description}</h3>
            <div className="data-point">
              <label className="profile-label">Date:</label>
              <div className="profile-data">{`${thisDate.getMonth() + 1}/${thisDate.getDate()}/${thisDate.getFullYear()}`}</div>
            </div>
            <div className="data-point">
              <label className="profile-label">Amount:</label>
              <div className="profile-data">${rowContent.alloParent ? rowContent.alloParent.displayAmount.toFixed(2).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : displayAmount.toFixed(2).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </div>
            <div className="data-point">
              <label className="profile-label">Card #:</label>
              <div className="profile-data">{ccNumber}</div>
            </div>
            <div className="data-point">
              <label className="profile-label">Approved?:</label>
              <div className="profile-data">
                {
                  approved === true
                  ?
                  <AiOutlineCheckCircle className='approve-expence-btn approved' /> 
                  :
                  approved === false
                  ?
                  <TiDeleteOutline className='approve-expence-btn denied' />
                  :
                  "Awaiting Approval"
                }    
              </div>
            </div>
              {
                editDetails &&
                <div className="data-point">
                  <label className="profile-label">Groups:</label>
                    <select
                      value={groupID}
                      name='groupID'
                      onChange={(e) => {
                        setGroup(e.target.value)
                        handleChange(e)
                      }}
                      className="data-point-select"
                    >
                      <option value='0'>Choose...</option>
                      {
                        groups.map(group => {
                          if(group.active){
                            return(
                              <option 
                                key={`group_${group.groupID}`}
                                value={group.groupID}  
                              >
                                {group.groupName}
                              </option>
                            )
                          }
                        })
                      }
                    </select>
                </div>
              }
            <div className="data-point">
              <label className="profile-label">Property:</label>
              {
                editDetails
                ?
                  <select
                    value={propertyID}
                    name='propertyID'
                    onChange={handleChange}
                    className="data-point-select"
                  >
                    <option value='0'>Choose...</option>
                    {
                      properties.map(property => {
                        if(groupID != 0){
                          if(property.groupID == groupID && property.active){
                            return(
                              <option 
                                key={`property_${property.id}`}
                                value={property.id}  
                              >
                                {property.name}
                              </option>
                            )
                          }
                        } else {
                          return(
                            <option 
                              key={`property_${property.id}`}
                              value={property.id}  
                            >
                              {property.name}
                            </option>
                          )
                        }
                      })
                    }
                  </select>
                :
                <div className="profile-data">{property && property.name}</div>
              }
            </div>
            <div className="data-point">
              <label className="profile-label">GL Code:</label>
              {
                editDetails
                ?
                <select
                  value={codeID}
                  name='codeID'
                  onChange={handleChange}
                  className="data-point-select"
                >
                  <option value='0'>Choose...</option>
                  {
                    codes.map(code => {
                      if(group != 0){
                        if(code.groupID == group && code.active){
                          return(
                            <option 
                              key={`code_${code.id}`}
                              value={code.id}
                            >
                              {code.codeName}
                            </option>
                          )
                        }
                      }else{
                        return(
                          <option 
                            key={`code_${code.id}`}
                            value={code.id}
                          >
                            {code.codeName}
                          </option>
                        )
                      }
                    })
                  }
                </select>
                :
                <div className="profile-data">{code && code.codeName}</div>
              }
            </div>
            <div className="data-point">
              <label className="profile-label">Type:</label>
              {
                editDetails
                ?
                <select
                  value={spendingLimitID}
                  name='spendingLimitID'
                  onChange={handleChange}
                  className="data-point-select"
                >
                  <option value='0'>Choose...</option>
                  {
                    limits.map(limit => {
                      if(limit.active){
                        return(
                        <option 
                          key={`limit_${limit.id}`}
                          value={limit.id}
                        >
                          {limit.name}
                        </option>
                    )}
                  })
                  }
                </select>

                :
                <div className="profile-data">{spendingLimit && spendingLimit.name}</div>
              }
            </div>
            <div className="data-point">
              <label className="profile-label">Receipts:</label>
                <div className="profile-data image-tags">{receipts.length > 0 ? receipts.map((receipt, idx) => (
                  <div className="image-tag">
                    <a href={`${baseUrl.substring(0, baseUrl.length-1)}${receipt.filePath}`} target="_blank" rel="noreferrer">Image {idx+1}</a>
                    {
                      editDetails
                      && 
                      <BiTrash className='trash-it' onClick={() => handleDeleteImage(receipt.id, idx)}/>
                    }
                  </div>
              )) : 
              rowContent.alloParent?.receipts.map((receipt, idx) => (
                <div className="image-tag">
                  <a href={`${baseUrl.substring(0, baseUrl.length-1)}${receipt.filePath}`} target="_blank" rel="noreferrer">Image {idx+1}</a>
                  {
                    editDetails
                    && 
                    <BiTrash className='trash-it' onClick={() => handleDeleteImage(receipt.id, idx)}/>
                  }
                </div>
              ))
              }</div>
            </div>
            <div className="data-point span-two">
              <input type="file" id='add-receipt-input' onChange={addReceipt} />
            </div>
            {
              imageLoading && (
                <img src={spinner} className='spinner-styles' alt="" />
              )
            }
            {
              imageLoadingError && (
                <p className="error-text">Error loading the image. Please ensure the file size is below 2mb and try again.</p>
              )
            }
            <div className="data-point">
              <label className="profile-label">Message:</label>
              {
                editDetails
                ?
                <textarea 
                  type="text" 
                  className='edit-textarea'
                  name="notes"
                  value={notes} 
                  onChange={handleChange}
                ></textarea>
                :
                <div className="profile-data">{notes}</div>
              }
            </div>
            {
              allocate
              &&
              <div className="data-point data-column allocate-section">
                <label className='profile-label'>Allocations:</label>
                <label className='profile-label'>Set All:</label>
                <div className="table allocation-table equal-spread">
                  <input type="checkbox" checked={equalize} className='equal-allocation-spread' onChange={() => {
                    setEqualize(!equalize);
                    equalizeAllocations();
                  }} />  
                  <select 
                    name="groupID" 
                    value={allocationGroup} 
                    className='table-edit'
                    onChange={(e) => {
                      setAllocationGroup(e.target.value);
                      allocateAll(e);
                    }}
                    >
                    <option value='0'>Choose...</option>
                    {
                      groups.map(group => {
                        if(group.active){
                          return(
                            <option 
                              key={`group_${group.groupID}`}
                              value={group.groupID}
                              >
                              {group.groupName}
                            </option>
                          )
                        }
                      })
                    }
                  </select>
                  <select 
                    name="propertyID" 
                    value={allocationProperty} 
                    className='table-edit'
                    onChange={(e) => {
                      setAllocationProperty(e.target.value);
                      allocateAll(e);
                    }}
                    >
                    <option value='0'>Choose...</option>
                    {
                      properties.map(property => {
                        if(allocationGroup === 0){
                          return (
                            <option 
                              key={`property_${property.id}`}
                              value={property.id}
                              >
                              {property.name}
                            </option>
                          )
                        }
                        else if(property.groupID == allocationGroup){
                          return(
                            <option 
                              key={`property_${property.id}`}
                              value={property.id}
                              >
                              {property.name}
                            </option>
                          )
                        }
                    })
                    }
                  </select>
                  <select 
                    name="codeID" 
                    value={allocationCode}
                    className='table-edit'
                    onChange={(e) => {
                      setAllocationCode(e.target.value);
                      allocateAll(e);
                    }}
                  >
                    <option value='0'>Choose...</option>
                    {
                      codes.map(code => {
                        if(allocationGroup === 0){
                          return(
                            <option 
                              key={`code_${code.id}`}
                              value={code.id}
                            >
                              {code.codeName}
                            </option>
                          )
                        }
                        else if(code.groupID == allocationGroup){
                          return(
                            <option 
                              key={`code_${code.id}`}
                              value={code.id}
                            >
                              {code.codeName}
                            </option>
                          )  
                        }
                      })
                    }
                  </select>            
                  <select 
                    name="spendingLimitID" 
                    value={allocationLimit}
                    className='table-edit'
                    onChange={(e) => {
                      setAllocationLimit(e.target.value);
                      allocateAll(e);
                    }}
                  >
                    <option value='0'>Choose...</option>
                    {
                      limits.map(limit => {
                        if(limit.active && limit.active){
                          return(
                            <option 
                              key={`code_${limit.id}`}
                              value={limit.id}
                            >
                              {limit.name}
                            </option>
                          )
                        }
                      })
                    }
                  </select>
                </div>
                <div className="table allocation-table">
                  <div className="th">Amount</div>
                  <div className="th">Group</div>
                  <div className="th">Property</div>
                  <div className="th">GL Code</div>
                  <div className="th">Type</div>
                  <BsPlusLg 
                    className='add-allo-btn' 
                    onClick={() => {
                      handleChangeAllocation({target: { value: {groupID: allocationGroup, codeID: allocationCode, propertyID: allocationProperty, spendingLimitID: allocationLimit}, name: "add"}}, null)
                      if(equalize) equalizeAllocations();
                    }} 
                  />
                  {
                    getAllocations().map((alloObj, idx) => {
                      return <>
                        <input 
                          type="text"
                          name="displayAmount"
                          value={alloObj.displayAmount} 
                          className='edit-details table-edit'
                          onChange={(e) => {
                            handleChangeAllocation(e, idx)
                            calcTotalAllo()
                          }}
                          />
                        <select 
                          name="groupID" 
                          value={alloObj.groupID} 
                          className='table-edit'
                          onChange={(e) => {
                            handleChangeAllocation(e, idx)
                          }}
                          >
                          <option value='0'>Choose...</option>
                          {
                            groups.map(group => {
                              if(group.active){
                                return(
                                  <option 
                                    key={`group_${group.groupID}`}
                                    value={group.groupID}
                                    >
                                    {group.groupName}
                                  </option>
                                )
                              }
                            })
                          }
                        </select>
                        <select 
                          name="propertyID" 
                          value={alloObj.propertyID} 
                          className='table-edit'
                          onChange={(e) => {
                            handleChangeAllocation(e, idx)
                          }}
                          >
                          <option value='0'>Choose...</option>
                          {
                            properties.map(property => {
                              if(alloObj.groupID){
                                if(alloObj.groupID == property.groupID && property.active)
                                return (
                                  <option 
                                    key={`property_${property.id}`}
                                    value={property.id}
                                    >
                                    {property.name}
                                  </option>
                                )
                              }
                              else{
                                return(
                                  <option 
                                    key={`property_${property.id}`}
                                    value={property.id}
                                    >
                                    {property.name}
                                  </option>
                                )
                              }
                          })
                          }
                        </select>
                        <select 
                          name="codeID" 
                          id="" 
                          value={alloObj.codeID} 
                          className='table-edit'
                          onChange={(e) => {
                            handleChangeAllocation(e, idx)
                          }}
                        >
                          <option value='0'>Choose...</option>
                          {
                            codes.map(code => {
                              if(alloObj.groupID){
                                if(alloObj.groupID == code.groupID && code.active){
                                  return(
                                    <option 
                                      key={`code_${code.id}`}
                                      value={code.id}
                                    >
                                      {code.codeName}
                                    </option>
                                  )
                                }
                              }
                              else{
                                return(
                                  <option 
                                    key={`code_${code.id}`}
                                    value={code.id}
                                  >
                                    {code.codeName}
                                  </option>
                                )
                              }
                          })
                          }
                        </select>            
                        <select 
                          name="spendingLimitID" 
                          id="" 
                          value={alloObj.spendingLimitID} 
                          className='table-edit'
                          onChange={(e) => {
                            handleChangeAllocation(e, idx)
                          }}
                        >
                          <option value='0'>Choose...</option>
                          {
                            limits.map(limit => {
                              if(limit.active && limit.active){
                                return(
                                  <option 
                                    key={`code_${limit.id}`}
                                    value={limit.id}
                                  >
                                    {limit.name}
                                  </option>
                                )
                              }
                            })
                          }
                        </select>            
                        <BiTrash 
                          className='trash-it allo-trash' 
                          onClick={() => {
                            handleChangeAllocation({target: { name: "delete"}}, idx);
                          }}
                        />          
                      </>
                    })
                  }
                </div>
                <div className='allocate-total'>
                  {
                    (allocationTotal - (rowContent.alloParent ? rowContent.alloParent.displayAmount : displayAmount)).toFixed(2) == 0 ? 
                    <div className="center">
                      <AiOutlineCheckCircle className='approve-expence-btn approved' />
                    </div> :
                    `{${(allocationTotal - (rowContent.alloParent ? rowContent.alloParent.displayAmount : displayAmount)).toFixed(2)}}`
                  }
                </div>
              </div>

            }
            {
              (User.type === "SuperUser" || !approved) &&
              <div className="buttons-row">
                {
                allocate ? 
                <button 
                  className="btn btn-primary btn-full-width"
                  disabled={(allocationTotal - displayAmount).toFixed(2) != 0} 
                  onClick={() => {
                    handleSaveAllocation()
                    setAllocate(!allocate)
                  }}>
                    Save
                  </button>
                : 
                editDetails ? 
                <button 
                  className="btn btn-primary btn-full-width" 
                  onClick={() => {
                    handleSaveExpense()
                    setEditDetails(!editDetails)}
                  }
                >
                  Save
                </button>
              :
                <button 
                  className="btn btn-primary btn-full-width" 
                  onClick={() => {
                    setAllocate(!allocate)
                }}>
                  Allocate
                </button>
                }
                {
                  allocate ?
                              
                  <button 
                    className="btn btn-danger btn-full-width" 
                    onClick={() => {
                      setAllocate(!allocate)
                    }}
                  >
                    Cancel
                  </button>
                  :
                  editDetails ? 
                  <button 
                    className="btn btn-danger btn-full-width" 
                    onClick={() => {
                      setEditDetails(!editDetails)}
                    }
                  >
                    Cancel
                  </button>
                  :
                  <button 
                    className="btn btn-primary btn-full-width" 
                    onClick={() => setEditDetails(!editDetails)}
                  >
                    Edit
                  </button>
                }
              </div>
            }
            {
              User.type === "SuperUser" &&
              <div className="delete-expense-container">
                <BiTrash className='trash-it' onDoubleClick={() => deleteExpense(id)}/>
              </div>
            }
          </div>
        </div>
        :
        <></>
      }
    </div>

  )
}

export default ExpenseRow