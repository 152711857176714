import React, { useEffect, useState } from 'react';
import CardHolderRow from '../components/CardHolderRow'
import DashboardHeader from '../components/DashboardHeader'
import Navbar from '../components/Navbar'
import { GiHamburgerMenu } from "react-icons/gi";
import CardHolderModal from '../components/CardHolderModal';
import PropertiesModal from '../components/PropertiesModal';
import PropertiesRow from '../components/PropertiesRow';
import { getData, postData } from '../Data/Data';

function Properties() {
  const [showNewModal, setShowNewModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [properties, setProperties] = useState([]);
  const [modProperty, setModProperty] = useState(0);
  const [newProperty, setNewProperty] = useState({ active: true});
  const [editIndex, setEditIndex] = useState();

  const handleChangeProperty = (e) => {
    setNewProperty((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value === "true" ? false : e.target.value === "false" ? true : e.target.value,
    }))
  }

  const handleChangeProperties = (e) => {
    const tempProperties = [...properties];
    if((tempProperties[editIndex][e.target.id] === null || tempProperties[editIndex][e.target.id] === "") && (e.target.id === "spendingLimits" || e.target.id === "approvalNeeded")){
      tempProperties[editIndex][e.target.id] = true;
    }
    tempProperties[editIndex][e.target.id] = e.target.value === "true" ? false : e.target.value === "false" ? true : e.target.value;

    if(e.target.id === "groupID"){
      if(e.target.value === ""){
        tempProperties[editIndex]["groupID"] = null;
        tempProperties[editIndex]["group"] = null;
      }
      else{
        groups.map(group => {
          if(group.groupID == e.target.value){
            tempProperties[editIndex]["group"] = group;
          }
        })
      }
    }
    
    setProperties(tempProperties);
    
  }

  
  const handleSaveProperty = async (newProperty) => {
    const response = await postData("api/users/SaveNewProperty", newProperty);
    setModProperty(modProperty + 1);
    setShowNewModal(false);
    setNewProperty({});
  }
  
  useEffect(async () => {
    const getGroups = await getData("api/users/getgroups")
    setGroups(getGroups);
    const getProperties = await getData("api/users/getproperties")
    setProperties(getProperties);
    setAllProperties(getProperties);
  }, [modProperty])

  const dispayNav = () => {
    const navbar = document.getElementById('navbar')
    navbar.style.display = 'flex';
  }

  const deleteProperty = () => {
    console.log("deleteProperty");
  }

  const searchProperties = (e) => {
    const tempArr = [];
    if(e.target.value == ""){
      setProperties(allProperties);
    }
    allProperties.map(property => {
      if(property.name.toLowerCase().includes(e.target.value.toLowerCase())){
        tempArr.push(property);
      }
    })
    setProperties(tempArr);
  }

  return (
    <div className="body-page dashboard-page">
      <div className="hamburger">
       <GiHamburgerMenu className='hamberger-icon' onClick={() => dispayNav()} />   
      </div>
      <DashboardHeader />
      <div className="dashboard-body dashboard-body-navbar row">
        <Navbar />
        <div className="dashboard-body-content">
          <h1 className='dashboard-h1'>Properties</h1>
          <button className="btn btn-primary btn-quarter-width margin-bottom-small" onClick={() => setShowNewModal(true)}>Add Property</button>
          <input type="text" placeholder='Search' onChange={searchProperties} />
          <div className="table properties-table">
            <div className="tr">
              <div className="th td">Property</div>  
              <div className="th td">Group</div>
              <div className="th td">Active</div>
            </div>
            {
              properties.map((property, idx) => {
                return (
                  <PropertiesRow
                    key={idx}
                    setEditIndex={() => setEditIndex(idx)}
                    property={property}
                    groups={groups}
                    deleteProperty={() => deleteProperty()}
                    handleChangeProperty={handleChangeProperties}
                  />)
              })
            }
          </div>
        </div>
      </div>
      {
        showNewModal
        ?
        <PropertiesModal 
          property={newProperty} 
          groups={groups} 
          handleChangeProperty={handleChangeProperty} 
          closeModal={() => setShowNewModal(false)} 
          handleSaveProperty={handleSaveProperty}
          newProperty={true}
        />
        :
        <></>
      }
    </div>
  )
}

export default Properties