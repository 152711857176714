import React, { useState, useEffect } from 'react';
import { GrClose } from "react-icons/gr";
import { BiTrash } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import CardHolderModal from './CardHolderModal';
import PropertiesModal from './PropertiesModal';
import { postData } from '../Data/Data';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";



function ApproversRow({
  property, 
  groups, 
  deleteProperty, 
  setEditIndex, 
  handleChangeProperty, 
  handleSaveProperty
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(true);
  const [addCard, setAddCard] = useState(true);

  const addCardFunc = () => {
    const addInput = document.getElementById("add-card-input");
    setAddCard(false);    
  }

  const updateProperty = async property => {
    const response = await postData("api/users/updateproperty", property);
    if(response === "Success"){
      setEditDetails(false);
      setShowDetails(false);
    }
  }
  
  return (
    <div>
      <div className="tr properties-table" onClick={() => {
        setEditIndex()
        setShowDetails(true)
        }}>
        <div className="td">{property.name}</div>  
        <div className="td">{property.group && property.group.groupName}</div> 
        <div className="td center">
              {
                property.active === true
                ?
                <AiOutlineCheckCircle className='approve-expence-btn approved' /> 
                :
                property.active === false
                ?
                <TiDeleteOutline className='approve-expence-btn denied' />
                :
                ""
              }
            </div> 
      </div>
      {
        showDetails
        ?
          <PropertiesModal 
            property={property} 
            groups={groups} 
            closeModal={() => setShowDetails(false)} 
            deleteProperty={deleteProperty} 
            handleChangeProperty={handleChangeProperty}
            handleSaveProperty={updateProperty}
          />
        :
        <></>
      }
    </div>
  )
}

export default ApproversRow