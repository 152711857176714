import React, { useState, useEffect } from 'react';
import { GrClose } from "react-icons/gr";
import { BiTrash } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import PropTypes from 'prop-types';


function GLModal({code, closeModal, groups, handleChangeCode, handleSaveCode}) {
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(true);
  const [addCard, setAddCard] = useState(true);

  const addCardFunc = () => {
    const addInput = document.getElementById("add-card-input");
  }

  return (
    <div className='popup-box'>
      <div className="overlay card-holder-details" onClick={() => closeModal()}></div>
      <div className="details-card">
        <GrClose className='close-btn' onClick={() => closeModal()} />
        <h3>GL Codes</h3>
        <div className="data-point">
          <label className='profile-label'>GL Code: </label>
          <input 
            type="text" 
            className='gl-edit' 
            id="codeName"
            value={code.codeName} 
            onChange={handleChangeCode}
          />
        </div>
        <div className="data-point">
          <label className='profile-label'>Group: </label>
          <select 
            name="" 
            id="groupID"
            onChange={handleChangeCode}
            value={code.groupID}
          >
            <option value="">Choose...</option>
            {
              groups.map(group => {
                if(group.active){
                  return(
                    <option value={group.groupID}>{group.groupName}</option>
                )}
              })
            }
          </select>
        </div>
        <div className="data-point">
          <label className="profile-label">Active:</label>
          {
            editDetails
            ?
            <input 
              type="Checkbox" 
              className='edit-details' 
              id="active"
              onChange={handleChangeCode}
              value={code.active} 
              checked={code.active}
            />
            :
            <div className="profile-data">{code.active ? "Yes" : "No"}</div>
          }
        </div>
        <button 
          className="btn btn-primary btn-full-width margin-top-small"
          onClick={() => handleSaveCode(code)}
          >Save</button>
      </div>
    </div>
  )
}

GLModal.defaultProps = {

};

GLModal.propTypes = {
  
};

export default GLModal