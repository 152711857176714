import React, { useEffect, useState } from 'react'
import DashboardHeader from '../components/DashboardHeader'
import { AiOutlineArrowUp } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import ExpenseRow from '../components/ExpenseRow';
import { getData, User, updateLS, postData } from '../Data/Data';
import Spinner from '../components/Spinner';
import { render } from 'react-dom';
import { debounce } from 'lodash';

function Search() {
  const [isLoading, setIsLoading] = useState(true);
  const [allExpenses, setAllExpenses] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [properties, setProperties] = useState([]);
  const [codes, setCodes] = useState([]);
  const [groups, setGroups] = useState([]);
  const [creditCards, setCreditCards] = useState([]);
  const [users, setUsers] = useState([]);
  const [limits, setLimits] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [editIndex, setEditIndex] = useState();
  const [total, setTotal] = useState();
  const [upload, setUpload] = useState();
  const [sortByProperty, setSortByProperty] = useState();
  const [sortByCHProperty, setSortByCHProperty] = useState();
  const [sortByCode, setSortByCode] = useState();
  const [sortByCH, setSortByCH] = useState();
  const [printing, setPrinting] = useState(false);
  const [separateAllocations, setSeparateAllocations] = useState(true);
  const [searchName, setSearchName] = useState();
  let currentID = 0;
  let currentPID = 0;
  let currentCID = 0;
  let totalSpend = 0;
  let totalCatSpend = 0;

  // setExpenses(GetData('api/users/allexpenses'));

  // useEffect(async() => {
  //   await fetchProperties();
  //   await fetchCodes();
  //   await fetchGroups();
  //   fetchCreditCards();
  //   fetchUsers();
  //   fetchSpendingLimits();
  //   await fetchUploads();
  // }, [])
  
  const fetchExpenses = async (month) => {
    if(month){
      setExpenses([]);
      setIsLoading(true);
      const getAllExpenses = await getData(`api/users/allexpenses/${month}`);
      setExpenses(getAllExpenses);
      setAllExpenses(getAllExpenses);
      
      let tempTotal = 0;
      getAllExpenses.map((expense) => {
        if(!separateAllocations && expense.alloParentID){
        } else if(separateAllocations && expense.allocations.length > 0){
        }else{
          tempTotal += expense.amount
        }
  
        expense.allocations.map(allocation => {
          allocation.displayAmount = (allocation.amount / 100).toFixed(2);
          properties.map(prop => {
            if(prop.id === allocation.propertyID){
              allocation.propertyName = prop.name 
            }
          })
          codes.map(code => {
            if(code.id === allocation.codeID){
              allocation.codeName = code.codeName 
            }
          })
          limits.map(limit => {
            if(limit.id === allocation.spendingLimitID){
              allocation.limitName = limit.name 
            }
          })
        })
      })
  
      setTotal((tempTotal / 100).toFixed(2))
  
      
      setIsLoading(false);
  
      return "";
    }
  }

  const fetchUploads = async () => {
    const getAllUploads = await getData('api/users/getuploads');
    console.log(getAllUploads);
    setUploads(getAllUploads.reverse());
    setUpload(getAllUploads[0]);
  }

  const fetchProperties = async () => {
    const getAllProperties = await getData('api/users/getproperties');
    setProperties(getAllProperties.sort((a, b) => {
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    }));
  }

  const fetchCodes = async () => {
    const getAllCodes = await getData('api/users/getcodes');
    setCodes(getAllCodes);
  }

  const fetchGroups = async () => {
    const getAllGroups = await getData('api/users/getgroups');
    setGroups(getAllGroups);
  }
  
  const fetchCreditCards = async () => {
    if(User.type === 'SuperUser' || User.type == "Accountant"){
      const getAllCards = await getData('api/users/getcards');
      setCreditCards(getAllCards);
    } else {
      const getAllCards = await getData(`api/users/getmycards/${User.userID}`);
      setCreditCards(getAllCards);
    }
  }
  
  const fetchUsers = async () => {
    const getAllUsers = await getData('api/users/GetAllActiveUsers');
    setUsers(getAllUsers);
  }
  
  const fetchSpendingLimits = async () => {
    const GetSpendingLimits = await getData('api/users/GetSpendingLimits');
    setLimits(GetSpendingLimits);
  }
    
  const handleChange  = (e) => {
    const tempExpArr = [...expenses];

    if(e.target.name === "receipts"){
      tempExpArr[editIndex]["receipts"].push(e.target.value);
    }
    else{
      tempExpArr[editIndex][e.target.name] = e.target.value;
    }

    if(e.target.name === "groupID"){
      if(e.target.value == 0){
        tempExpArr[editIndex][e.target.name] = null;
        tempExpArr[editIndex]["group"] = null;
      }
      else{
        groups.map(group => {
          if(group.groupID == e.target.value){
            tempExpArr[editIndex]["group"] = group;
          }
        })
      }
    }

    if(e.target.name === "propertyID"){
      if(e.target.value == 0){
        tempExpArr[editIndex][e.target.name] = null;
        tempExpArr[editIndex]["property"] = null;
      }
      else{
        properties.map(property => {
          if(property.id == e.target.value){
            tempExpArr[editIndex]["property"] = property;
          }
        })
      }
    }

    if(e.target.name === "codeID"){
      if(e.target.value == 0){
        tempExpArr[editIndex][e.target.name] = null;
        tempExpArr[editIndex]["code"] = null;
      }
      else{
        codes.map(code => {
          if(code.id == e.target.value){
            tempExpArr[editIndex]["code"] = code;
          }
        })    
      }
    }

    if(e.target.name === "spendingLimitID"){
      if(e.target.value == 0){
        tempExpArr[editIndex][e.target.name] = null;
        tempExpArr[editIndex]["spendingLimit"] = null;
      }
      else{
        limits.map(limit => {
          if(limit.id == e.target.value){
            tempExpArr[editIndex]["spendingLimit"] = limit;
          }
        })    
      }
    }

    setExpenses(tempExpArr);
  }

  const removeImage = (idx) => {
    const tempExpArr = [...expenses];

    const imageArr = tempExpArr[editIndex]["receipts"]
    
    const tempImageArr = [];
    imageArr.map((image, index) => {
      if(index != idx){
        tempImageArr.push(image)
      }
    })
    tempExpArr[editIndex]["receipts"] = tempImageArr;
    setExpenses(tempExpArr);
  }

  const calcTotal = expenseArr => {
    let tot = 0;
    expenseArr.map(exp => {
      if(!separateAllocations && exp.alloParentID){
        return '';
      } else if(separateAllocations && exp.allocations.length > 0){
        return '';
      } else{
        tot += exp.amount
      }
    })
    setTotal((tot / 100).toFixed(2));
  }
  
  const handleSaveExpense = async () => {
    const saveExpense = {

      id: expenses[editIndex].id,
      propertyID: expenses[editIndex].propertyID,
      codeID: expenses[editIndex].codeID,
      groupID: expenses[editIndex].groupID,
      spendingLimitID: expenses[editIndex].spendingLimitID,
      notes: expenses[editIndex].notes,
    }
    const response = await postData("api/users/updateExpense", saveExpense);
  };

  const handleChangeUpload = (e) => {
    setUpload(e.target.value);
  }
  
  const handleSort = (e) => {
    setIsLoading(true);
    const tempUploads = [...allExpenses];
    if(e.target.value !== 7){
      setSortByCH(false);
    }
    if(e.target.value !== 3 || e.target.value !== 4){
      setSortByProperty(false);
    }
    if(e.target.value !== 8){
      setSortByCode(false);
    }
    if(e.target.value !== 9){
      setSortByCHProperty(false);
    }
    if(e.target.value == '1'){
      setSeparateAllocations(false);
      tempUploads.sort((a, b) => new Date(b.expense_Date) - new Date(a.expense_Date))
    }
    else if(e.target.value == '2'){
      setSeparateAllocations(false);
      tempUploads.sort((a, b) => new Date(a.expense_Date) - new Date(b.expense_Date))
    }
    // else if(e.target.value == '3'){
    //   setSortByProperty(true);
    //   tempUploads.sort((a, b) => {
    //     if(!b.property){
    //       return 1
    //     }
    //     else if(!a.property){
    //       return -1
    //     }
    //     return b.propertyID - a.propertyID || a.codeID - b.codeID || a.spendingLimitID - b.spendingLimitID
    //   })
    // }
    else if(e.target.value == '4'){
      setSortByProperty(true);
      tempUploads.sort((b, a) => {
        if(!b.propertyID){
          return 1
        }
        else if(!a.propertyID){
          return -1
        }
        else if(a.property?.name > b.property?.name){
          return -1
        }
        else if(a.property?.name < b.property?.name){
          return 1
        }
        return a.codeID - b.codeID || a.spendingLimitID - b.spendingLimitID
      })
    }
    else if(e.target.value == '8'){
      setSortByCode(true);
      tempUploads.sort((a, b) => {
        if(a.code?.codeName > b.code?.codeName){
          return -1
        }
        else if(a.code?.codeName < b.code?.codeName){
          return 1
        }
        return b.propertyID - a.propertyID || a.spendingLimitID - b.spendingLimitID
      })
    }
    else if(e.target.value == '9'){
      setSortByCHProperty(true);
      tempUploads.sort((a, b) => {
        if(a.cardHolderName < b.cardHolderName){
          return -1
        }
        else if(a.cardHolderName > b.cardHolderName){
          return 1
        }
        return a.propertyID - b.propertyID || a.codeID - b.codeID || a.spendingLimitID - b.spendingLimitID
      })
    }
    else if(e.target.value == '5'){
      setSeparateAllocations(false);
      tempUploads.sort((b, a) => a.amount - b.amount)
    }
    else if(e.target.value == '6'){
      setSeparateAllocations(false);
      tempUploads.sort((a, b) => a.amount - b.amount)
    }
    else if(e.target.value == '7'){
      setSortByCH(true);
      tempUploads.sort((a, b) => {
        if(a.cardHolderName < b.cardHolderName){
          return -1
        }
        else if(a.cardHolderName > b.cardHolderName){
          return 1
        }
        return a.propertyID - b.propertyID || a.codeID - b.codeID || a.spendingLimitID - b.spendingLimitID
      })
    }
    
    calcTotal(tempUploads);
    setExpenses(tempUploads);
    setIsLoading(false);
  }

  const handleSortCard = (e) => {
    if(e.target.value == ""){
      setExpenses(allExpenses);
      setSearchName(null);
      return "";
    }
    else{
      setSearchName(e.target.value);
    }
    const tempArr = []
    allExpenses.map(expense => {
      if(expense.ccNumber == e.target.value){
        tempArr.push(expense);
      }
    })
    
    calcTotal(tempArr);
    setExpenses(tempArr);
  }
  
  const handleSortHolder = (e) => {
    if(e.target.value == ""){
      setExpenses(allExpenses);
      return "";
    }
    const tempArr = []
    allExpenses.map(expense => {
      if(expense.userID == e.target.value){
        tempArr.push(expense);
      }
    })

    let name = users.map(user => {
      if(e.target.value == user.userID){
        return user.name;
      }
    })
    setSortByCH(true);

    tempArr.sort((a, b) => {
      if(!b.property){
        return 1
      }
      else if(!a.property){
        return -1
      }
      return b.propertyID - a.propertyID || a.codeID - b.codeID || a.spendingLimitID - b.spendingLimitID
    })

    setSearchName(name);
    calcTotal(tempArr);
    setExpenses(tempArr);
  }
  
  const handleSortProperty = (e) => {
    if(e.target.value == ""){
      setExpenses(allExpenses);
      return "";
    }
    const tempArr = []
    allExpenses.map(expense => {
      if(expense.propertyID == e.target.value){
        tempArr.push(expense);
      }
    })

    let name = properties.map(property => {
      if(e.target.value == property.id){
        return property.name;
      }
    })
    
    tempArr.sort((a, b) => {
      setSortByProperty(true);
      return a.codeID - b.codeID
    })

    setSearchName(name);
    calcTotal(tempArr);
    setExpenses(tempArr);
  }
  
  const handleSortCode = (e) => {
    if(e.target.value == ""){
      setExpenses(allExpenses);
      return "";
    }
    const tempArr = []
    allExpenses.map(expense => {
      if(expense.codeID == e.target.value){
        tempArr.push(expense);
      }
    })

    let name = codes.map(code => {
      if(e.target.value == code.id){
        return code.name;
      }
    })
    
    tempArr.sort((a, b) => {
      setSortByProperty(true);
      return a.spendingLimitID - b.spendingLimitID
    })

    setSearchName(name);
    calcTotal(tempArr);
    setExpenses(tempArr);
  }

  const deleteExpense = async (id) => {
    const tempExpArr = [...expenses];
    const newArr = [];
    tempExpArr.map((exp, idx) => {
      if(idx !== editIndex){
        newArr.push(exp)
      }
    })

    setExpenses(newArr);

    const response = await getData(`api/users/deletedxpense/${id}`);
    
    const getAllExpenses = await getData(`api/users/allexpenses/${upload}`);
    setAllExpenses(getAllExpenses);
  }

  const handlePrintReport = () => {
    const header = document.getElementById("top-header")
    const sortBar = document.getElementById("search-sort-bar")
    const h2 = document.getElementById("h2-expense-header")
    const report = document.getElementById("report")
    setPrinting(true)
    header.style.display = "none"
    sortBar.style.display = "none"
    h2.style.display = "none"
    report.style.overflow = "visible"
    report.style.height = "auto"
    setTimeout(() => {
      window.print()
      header.style.display = "flex"
      sortBar.style.display = "flex"
      h2.style.display = "block"
      report.style.overflowY = "scroll"
      report.style.height = "calc(100vh - 300px)"
      setPrinting(false);
    }, 50)
  }

  const handleChangeAllocation = (e, idx) => {
    const tempExpArr = [...expenses];
    let adding = false;
    if(e.target.name == "add"){
      e.target.value.propertyName = getPropertyName(e.target.value.propertyID)
      e.target.value.codeName = getCodeName(e.target.value.codeID)
      e.target.value.limitName = getLimitName(e.target.value.spendingLimitID)
      tempExpArr[editIndex].allocations.push(e.target.value);
      setSeparateAllocations(false);
    }
    else if(e.target.name == "delete"){
      let alloArr = []
      tempExpArr[editIndex].allocations.map((allo, index) => {
        if(idx !== index){
          alloArr.push(allo);
        }
        else{
          getData(`api/users/deletedxpense/${allo.id}`);
        }
      })
      tempExpArr[editIndex].allocations = alloArr;
    }
    else{
      tempExpArr[editIndex].allocations[idx][e.target.name] = e.target.value
      if(e.target.name == "displayAmount"){
        tempExpArr[editIndex].allocations[idx].amount = (e.target.value * 100).toFixed(0);
      }
      else if(e.target.name == "propertyID"){
        const propName = getPropertyName(e.target.value); 
        tempExpArr[editIndex].allocations[idx].propertyName = propName;
      }
      else if(e.target.name == "codeID"){
        const codeName = getCodeName(e.target.value); 
        tempExpArr[editIndex].allocations[idx].codeName = codeName;
      }
      else if(e.target.name == "spendingLimitID"){
        const limitName = getLimitName(e.target.value); 
        tempExpArr[editIndex].allocations[idx].limitName = limitName;
      }
    }
    setExpenses(tempExpArr);
  }

  const getPropertyName = (id) => {
    let propName; 
    properties.forEach(prop => {
      if(prop.id == id){
        propName = prop.name;
      }
    })
    return propName;
  }

  const getCodeName = (id) => {
    let codeName; 
    codes.forEach(code => {
      if(code.id == id){
        codeName = code.codeName;
      }
    })
    return codeName;
  }

  const getLimitName = (id) => {
    let limitName; 
    limits.forEach(limit => {
      if(limit.id == id){
        limitName = limit.name;
      }
    })
    return limitName;
  }
  
  const handleSearch = async (e) => {
    console.log(e.target.value)
    console.log({searchTerm: e.target.value});
    setExpenses([]);

    setIsLoading(true);
    const path = `api/users/search/?searchTerm=${e.target.value}`;
    const response = await getData(path).then((expenses) => {
      console.log({expenses});
      let tempTotal = 0;
      expenses.map((expense) => {
        if(!separateAllocations && expense.alloParentID){
        } else if(separateAllocations && expense.allocations.length > 0){
        }else{
          tempTotal += expense.amount
        }
  
        expense.allocations.map(allocation => {
          allocation.displayAmount = (allocation.amount / 100).toFixed(2);
          properties.map(prop => {
            if(prop.id === allocation.propertyID){
              allocation.propertyName = prop.name 
            }
          })
          codes.map(code => {
            if(code.id === allocation.codeID){
              allocation.codeName = code.codeName 
            }
          })
          limits.map(limit => {
            if(limit.id === allocation.spendingLimitID){
              allocation.limitName = limit.name 
            }
          })
        })
      })
  
      setTotal((tempTotal / 100).toFixed(2))
  
      
      setIsLoading(false);
  
      console.log({expenses});
      setExpenses(expenses);
  
    });
          
  }  

  const searchHandler = debounce(handleSearch, 1000);

  return  (
    <div className="body-page">
      <DashboardHeader />
      <div className="dashboard-body">
        <div className="body-content">
          <h2 id='h2-expense-header'>My Expenses</h2>
          <div id='search-sort-bar' className="search-sort flex-row-wrap">
            <div className="search-container">
              <div className="search-bar-container">
                <FaSearch className='search-icon'/>
                <input className='search-input' type="text" onChange={searchHandler} />
              </div>
              <button className='btn btn-primary print-btn custom-print-btn' onClick={handlePrintReport}>Print</button>
            </div>
          </div>
          <div className="table content-table expense-content">
            <div className={!printing ? "tr th expense-row " : "tr th expense-row expense-row-print"}>
              <div className="td hd date">Date</div>
              <div className="td hd expense-desc description">Description</div>
              <div className="td hd ccnumber">CC#</div>
              <div className="td hd hidden-lable amount">Amount</div>
              <div className="td hd prop-code-select property">Property</div>
              <div className="td hd prop-code-select code">GL Code</div>
              <div className="td hd limit">Type</div>
              <div className="td hd print-hide approveddenied">Approved</div>
              {!printing && 
                <div className="td hd print-hide awaitings">Warning</div>
              }
            </div>
            <div id='report' className="table-body expense-table">
              {
                searchName &&
                <div className="new-user-row">Name: {searchName}</div>
              }
              {
                !isLoading 
                ? 
                expenses.map((item, idx) => {
                  let tempUser;
                  let tempCat;
                  let setTotalSpend;
                  let setCatSpend;

                  if(sortByCH){
                    if(item.allocations.length < 1){
                      if(currentID !== item.userID){
                        currentID = item.userID;
                        setTotalSpend = totalSpend;
                        totalSpend = 0;
                        tempUser = users.map(user => {
                          if(user.userID == item.userID){
                            return user.name
                          }
                        })
                      }
                      totalSpend += item.amount;
                    }
                  }
                  if(sortByProperty){
                    if(item.allocations.length < 1){
                      if(currentPID !== item.propertyID){
                        currentPID = item.propertyID;
                        setTotalSpend = totalSpend;
                        totalSpend = 0;
                        tempUser = properties.map(property => {
                          if(property.id == item.propertyID){
                            return property.name
                          }
                        })
                      }
                      totalSpend += item.amount;
                    }
                  }
                  if(sortByCode){
                    if(item.allocations.length < 1){
                      if(currentCID !== item.codeID){
                        currentCID = item.codeID;
                        setTotalSpend = totalSpend;
                        totalSpend = 0;
                        tempUser = codes.map(code => {
                          if(code.id == item.codeID){
                            return code.codeName
                          }
                        })
                      }
                      totalSpend += item.amount;
                    }
                  }
                  if(sortByProperty || sortByCH || sortByCode){
                    if(item.allocations.length < 1){
                      if(currentCID !== item.codeID || currentPID !== item.propertyID){
                        currentCID = item.codeID;
                        currentPID = item.propertyID;
                        setCatSpend = totalCatSpend;
                        totalCatSpend = 0;
                        tempCat = "Hey";
                      }
                      totalCatSpend += item.amount;
                    }
                  }
                  if(sortByCHProperty){
                    if(item.allocations.length < 1){
                      if(currentPID !== item.propertyID){
                        currentPID = item.propertyID;
                        setCatSpend = totalCatSpend;
                        totalCatSpend = 0;
                        tempCat = "Hey";
                      }
                      totalCatSpend += item.amount;
                      if(currentID !== item.userID){
                        currentID = item.userID;
                        setTotalSpend = totalSpend;
                        totalSpend = 0;
                        tempUser = users.map(user => {
                          if(user.userID == item.userID){
                            return user.name
                          }
                        })
                      }
                      totalSpend += item.amount;
                    }
                  }
                  return printing ? (
                    <>
                      {setCatSpend !== 0 && tempCat ? 
                      <div className="total-cat-row">
                        ${(setCatSpend / 100).toFixed(2).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </div> : ""}
                      {setTotalSpend !== 0 && tempUser ? 
                      <div className="total-spand-row">
                        <div></div>
                        <div></div>
                        <div className='pagebreak'>Total Spend: ${(setTotalSpend / 100).toFixed(2).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      </div> : ""}
                      {tempUser ? <div className="new-user-row">{tempUser}</div> : ""}
                      {
                        item.allocations.length < 1 &&
                        <ExpenseRow 
                            key={`expense_${item.id}`} 
                            properties={properties} 
                            codes={codes} 
                            rowContent={item} 
                            limits={limits}
                            setEditIndex={() => setEditIndex(idx)}
                            handleChange={handleChange}
                            handleSaveExpense={handleSaveExpense}
                            removeImage={removeImage}
                            printing={printing}
                            handleChangeAllocation={handleChangeAllocation}
                            groups={groups}
                            deleteExpense={deleteExpense}
                          />
                      }
                      </>
                    )
                    :
                    !printing ? !item.alloParentID && (
                      <>
                        {setCatSpend !== 0 && tempCat ? 
                        <div className="total-cat-row">
                          ${(setCatSpend / 100).toFixed(2).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div> : ""}
                        {setTotalSpend !== 0 && tempUser ? 
                        <div className="total-spand-row">
                          <div></div>
                          <div></div>
                          <div className='pagebreak'>Total Spend: ${(setTotalSpend / 100).toFixed(2).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        </div> : ""}
                        {tempUser ? <div className="new-user-row">{tempUser}</div> : ""}
                        <ExpenseRow 
                          key={`expense_${item.id}`} 
                          properties={properties} 
                          codes={codes} 
                          rowContent={item} 
                          limits={limits}
                          setEditIndex={() => setEditIndex(idx)}
                          handleChange={handleChange}
                          handleSaveExpense={handleSaveExpense}
                          removeImage={removeImage}
                          printing={printing}
                          handleChangeAllocation={handleChangeAllocation}
                          groups={groups}
                          deleteExpense={deleteExpense}
                        />
                      </>
                    )
                    :
                    ""
                  })
                :
                ""
              }
              <div className="total-cat-row">
                ${(totalCatSpend / 100).toFixed(2).toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </div>
            <div className={!printing ? "tr th expense-row" : "tr th expense-row expense-row-print"}>
              {
                !printing &&
                <div className="td hd"></div>
              }
              <div className="td hd">Total Spend: ${total && total.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
              <div className="td hd"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Search