import React from 'react'
import { NavLink } from 'react-router-dom'
import { logoutUser, User } from '../Data/Data'

function DashboardHeader() {
  return (
    <div id="top-header" className='dashboard-header'>
      <h1>Dashboard</h1>
      <ul>
        <li><NavLink to='/Dashboard' className={({ isActive }) => (isActive ? 'nav-link active-header-link' : 'nav-link')}>Home</NavLink></li>
        {
          (User.type == "SuperUser" || User.isApprover) &&
          <li><NavLink to='/Approve-Expenses' className={({ isActive }) => (isActive ? 'nav-link active-header-link' : 'nav-link')}>Approve</NavLink></li>
        }
        {
          User.type == "SuperUser" &&
          <li><NavLink to='/Manage-Card-Holders' className={({ isActive }) => (isActive ? 'nav-link active-header-link' : 'nav-link')}>Admin</NavLink></li>
        }
        <li><NavLink to='/My-Profile' className={({ isActive }) => (isActive ? 'nav-link active-header-link' : 'nav-link')}>Profile</NavLink></li>
        <li><NavLink to='/Reports' className={({ isActive }) => (isActive ? 'nav-link active-header-link' : 'nav-link')}>Reports</NavLink></li>
        <li><NavLink to='/Search' className={({ isActive }) => (isActive ? 'nav-link active-header-link' : 'nav-link')}>Search</NavLink></li>
        <li><NavLink to='/' onClick={() => logoutUser()} className={({ isActive }) => (isActive ? 'nav-link active-header-link' : 'nav-link')}>Logout</NavLink></li>
      </ul>
    </div>
  )
}

export default DashboardHeader