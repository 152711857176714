import React, { useState } from 'react';
import { BiTrash } from "react-icons/bi";
import { postData } from '../Data/Data';
import GLModal from './GLModal';
import SpendingLimitModal from './SpendingLimitModal';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";

function SpendingLimitRow({
  limit,
  deleteLimit,
  setEditIndex,
  handleChangeLimit,
}) {
  const [showModal, setShowModal] = useState(false);

  const handleSaveLimit = () => {
    const response = postData("api/users/UpdateSpendingLimit", limit);
    setShowModal(false);


  }

  return (
    <div>
      <div 
        className='gl-code-row limit-row' 
        onClick={() => {
          setEditIndex()
          setShowModal(true)
        }}>
        <p className='gl-text'>{limit.name}</p>
        <p className='gl-text'>${limit.displayLimit}</p>
        <div className="td center">
          {
            limit.active === true
            ?
            <AiOutlineCheckCircle className='approve-expence-btn approved' /> 
            :
            limit.active === false
            ?
            <TiDeleteOutline className='approve-expence-btn denied' />
            :
            ""
          }
        </div>
        {/* <BiTrash className='trash-it' onClick={() => {
          setShowModal(false)
          deleteLimit(limit.id)}}/> */}
      </div>
      {
        showModal
        ?
        <SpendingLimitModal 
          limit={limit} 
          closeModal={() => setShowModal(false)} 
          handleChangeLimit={handleChangeLimit}
          handleSaveLimit={handleSaveLimit}
        />
        :
        <></>
      }
    </div> 
  )
}

export default SpendingLimitRow