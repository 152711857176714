import axios from "axios";
// export const baseUrl = 'https://localhost:7036/';
export const baseUrl = 'https://www.jenkinsatracker.com/expenseapi/';

export const User = getStoredUser();
const header = {
  "Access-Control-Allow-Origin" : "*",
  "Access-Control-Allow-Credentials" : true,
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Authorization': `bearer ${User.token}`,
}

getStoredUser();

export async function getData(endPoint){
  var response = await fetch(`${baseUrl}${endPoint}`, {
    method: 'GET',
    headers: header
  });
  var data = await response.json();
  return data;
}

export async function LoginUser(){
  var response = await fetch(`${baseUrl}api/auth/login`, {
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin" : "*",
      "Access-Control-Allow-Credentials" : true,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({
      username: User.username,
      password: User.password
    })
  });
  
  var data = await response.json();
  if(data === "Wrong password." || data === "User not found."){
    return false;
  }

  User.token = data.token; 
  User.name = data.name; 
  User.position = data.position; 
  User.email = data.email; 
  User.userID = data.userID; 
  User.username = data.username; 
  User.type = data.type; 
  User.approverID = data.approverID; 
  User.password = null; 
  User.isApprover = data.isApprover;
  await updateLS();
  return true;
}

export async function postData(endPoint, dataObj){
  var response = await fetch(`${baseUrl}${endPoint}`, {
    method: 'POST',
    headers: header,
    body: JSON.stringify(dataObj)
  });
  var data = await response.json();
  return data;
}

export async function addFile(data){
  await axios.post(`${baseUrl}api/users/ProcessImports`, data)
  .then(res => {
    var returnData = res.data;
    return returnData;
  })
  .catch(error => console.log(error));
}

export async function patchData(endPoint, dataObj){
  var response = await fetch(`${baseUrl}${endPoint}`, {
    method: 'PATCH',
    headers: header,
    body: JSON.stringify(dataObj)
  });
  var data = await response.json();
  return data;
}

export function updateLS(){
  localStorage.setItem("User", JSON.stringify(User));
}

function getStoredUser(){
  if(localStorage.getItem("User") === null){
    return {
      userID: "",
      name: "",
      username: "",
      email: "",
      phone: "",
      password: "",
      position: "",
      token: ""
    }
  }
  else{
    return JSON.parse(localStorage.getItem("User"));
  }
}

export function logoutUser(){
  localStorage.clear();
  User.token = null;
}