import React, { useEffect, useState } from 'react';
import CardHolderRow from '../components/CardHolderRow'
import DashboardHeader from '../components/DashboardHeader'
import Navbar from '../components/Navbar'
import { GiHamburgerMenu } from "react-icons/gi";
import CardHolderModal from '../components/CardHolderModal';
import PropertiesModal from '../components/PropertiesModal';
import PropertiesRow from '../components/PropertiesRow';
import GLModal from '../components/GLModal';
import GLCodesRow from '../components/GLCodesRow';
import SpendingLimitRow from '../components/SpendingLimitRow';
import SpendingLimitModal from '../components/SpendingLimitModal';
import { getData, postData } from '../Data/Data';

function SpendingLimits() {
  const [showNewModal, setShowNewModal] = useState(false);
  const [spendingLimits, setSpendingLimits] = useState([]);
  const [modLimits, setModLimits] = useState(0);
  const [newLimit, setNewLimit] = useState({});
  const [editIndex, setEditIndex] = useState();
  const emptyLimit = {
    id: 1,
    name: "",
    limit: null,
  };

  useEffect(async () => {
    const getLimits = await getData("api/users/GetSpendingLimits");
    setSpendingLimits(getLimits);
  }, [modLimits])

  const dispayNav = () => {
    const navbar = document.getElementById('navbar')
    navbar.style.display = 'flex';
  }

  const deleteLimit = async id => {
    const response = postData("api/users/deletespendinglimit", {id: id});
    setModLimits(modLimits + 1);
  }

  const handleSaveLimit = async newLimit => {
    const response = await postData("api/users/SaveNewSpendingLimit", newLimit);
    setModLimits(modLimits + 1);
    setShowNewModal(false);
    setNewLimit({});
  }

  const handleChangeLimit = (e) => {
    setNewLimit((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value === "true" ? false : e.target.value === "false" ? true : e.target.value,
    }))
  }

  const handleChangeLimits = (e) => {
    const tempLimits = [...spendingLimits];
    tempLimits[editIndex][e.target.id] = e.target.value === "true" ? false : e.target.value === "false" ? true : e.target.value;
    setSpendingLimits(tempLimits);
  }

  return (
    <div className="body-page dashboard-page">
      <div className="hamburger">
       <GiHamburgerMenu className='hamberger-icon' onClick={() => dispayNav()} />   
      </div>
      <DashboardHeader />
      <div className="dashboard-body dashboard-body-navbar row">
        <Navbar />
        <div className="dashboard-body-content">
          <h1 className='dashboard-h1'>Spending Limits</h1>
          <button className="btn btn-primary btn-quarter-width margin-bottom-xl" onClick={() => setShowNewModal(true)}>Add Spending Limit</button>
          <div className="table">
            <div className="tr limit-row">
              <div className="th td">Type</div>
              <div className="th td">Spending Limit</div>
              <div className="th td">Active</div>
            </div>
            {
              spendingLimits.map((limit, idx) => {
                return <SpendingLimitRow 
                    key={`limit_${limit.id}`} 
                    setEditIndex={() => setEditIndex(idx)} 
                    limit={limit} 
                    setModLimits={setModLimits}
                    modLimits={modLimits}
                    deleteLimit={deleteLimit}
                    handleChangeLimit={handleChangeLimits}
                  />
              })
            }
          </div>
        </div>
      </div>
      {
        showNewModal
        ?
        <SpendingLimitModal 
          limit={newLimit} 
          closeModal={() => setShowNewModal(false)}
          handleChangeLimit={handleChangeLimit}
          handleSaveLimit={handleSaveLimit}
        />
        :
        <></>
      }
    </div>
  )
}

export default SpendingLimits