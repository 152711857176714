import React, { useState, useEffect } from 'react';
import { GrClose } from "react-icons/gr";
import { BiTrash } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import PropTypes from 'prop-types';
import { getData } from '../Data/Data';


function ApproversModal({
  approver, 
  users, 
  closeModal, 
  deleteApprover, 
  newApprover, 
  handleSave,
  setChange,
  newApprovers,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(true);
  const [addCard, setAddCard] = useState(true);
  const [approverNewID, setApproverNewID] = useState(null);
  const [approverID, setApproverID] = useState(approver.userID);

  const addCardFunc = () => {
    const addInput = document.getElementById("add-card-input");
  }

  const setNewApprover = (e) => {
    setApproverNewID(e.target.value);
  }

  const removeMyApprover = async (id) => {
    const response = await getData(`api/users/RemoveApprover/${id}`);
    setChange()
  }
  
  const addMyApprover = async (approveeID) => {
    const response = await getData(`api/users/SetApprover/${approver.userID}/${approveeID}`);
    setChange()
  }

  return (
    <div className='popup-box'>
      <div className="overlay card-holder-details" onClick={() => closeModal()}></div>
      <div className="details-card">
        <GrClose className='close-btn' onClick={() => closeModal()} />
        <h3>Approver</h3>
        <div className="data-point">
          <label className="profile-label">Name:</label>
          {
            newApprover
            ?
            <select className='edit-details approver-list' onChange={setNewApprover}>
              {
                newApprovers.map(user => {
                  if(!user.isApprover){
                    return (
                      <option key={`approver_${user.userID}`} value={user.userID}>{user.name}</option>)
                  }
                })
              }
            </select>
            :
            <div className="profile-data">{approver.name}</div>
          }
        </div>
        {
          !newApprover &&
          <>
            <div className="data-point">
              <label className='profile-label'>Approvees: </label>
              <div className="profile-data">
                <div className="profile-card">
                  {/* {
                    approver.approvees.map(approvee => {
                      return <div key={approvee.id} className="profile-card">
                        <div className="approvee-list">
                          {approvee.name}
                          {
                            editDetails
                            &&
                            <BiTrash onClick={() => removeMyApprover(approvee.userID)} className='trash-it'/>
                          }
                          </div>
                        </div>
                    })
                  } */}
                </div>
              </div>
            </div>
            
            <div className="data-point">
              <label className="profile-label">Add:</label>
              <select 
                className='edit-details approver-list'
                onChange={(e) => addMyApprover(e.target.value)}
              >
                {
                  users.map(user => {
                    if(user.userID !== approver.userID){
                      return <option key={`approvee_${user.userID}`} value={user.userID}>{user.name}</option>
                    }
                  })
                }
              </select>
            </div>
          </>
        }
        {
          newApprover
          ?
          <button 
            className="btn btn-primary btn-full-width margin-top-small" 
            onClick={() => handleSave(approverNewID)}
          >
            Save
          </button>
          :
          <button 
            className="btn btn-primary btn-full-width margin-top-small" 
            onClick={() => deleteApprover(approverID)} 
          >
            Delete
          </button>

        }
      </div>
    </div>
  )
}

ApproversModal.defaultProps = {
  newApprover: false
};

ApproversModal.propTypes = {
  newApprover: PropTypes.bool
};

export default ApproversModal