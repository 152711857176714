import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { LoginUser, User } from '../Data/Data';


export default function Login() {
  const [notification, setNotification] = useState("Login Below");
  const navigate = useNavigate();
  const [loginUser, setLoginUser] = useState({
    username: "",
    password: ""
  })

  const handleLogin = async (e) => {
    e.preventDefault();
    User.username = loginUser.username;
    User.password = loginUser.password;
    const loggedIn = await LoginUser();
    if(loggedIn){
      window.location.href = '/dashboard';
    }
    else{
      setNotification("Incorrect Username or Password");
    }
  }

  const updateUser = (e) => {
    setLoginUser((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }

  return (
    <div className='body-page flex-center'>
      <form onSubmit={handleLogin} className="card login-card">
        <h1 className='header-med'>Expense Tracker</h1>
        <p className={ notification === "Login Below" ? 'text-light' : "denied" } >{notification}</p>
        <div className="welcome-banner-section">
          <label htmlFor="email" className='text-light'>Username</label>
          <input 
            type="text" 
            name='Username' 
            id='username' 
            className='underline-input width-full margin-bottom-med ' 
            value={loginUser.username}
            onChange={updateUser}
          />
          <label htmlFor="password" className='text-light'>Password</label>
          <input 
            type="password" 
            name='Password' 
            id='password' 
            className='underline-input width-full margin-bottom-small' 
            value={loginUser.password}
            onChange={updateUser}
          />
          {/* <div className="remember-forgot">
            <div className="remember">
              <Link to='/forgot-password' >Forgot Password?</Link>
            </div>
          </div> */}
        </div>
        <button className='btn btn-primary btn-3q-width font-bold margin-top-large' type='submit' >Login</button>
      </form>
    </div>
  )
}